import React, { useEffect, useState } from "react";
import Map from "../../components/map/map";
import Mapinput from "../../components/map/input";
import classes from "./voir_colis_disponible.module.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import TrajetCard from "../../components/cards/trajetCard/trajetCard";
import { Helmet } from "react-helmet-async";

const VoirColisDispo = () => {
    const location = useLocation();
    const { place1, place2 } = location.state || {}
    const [trajets, setTrajets] = useState([]);
    const [selectedPlace1, setSelectedPlace1] = useState(place1 || {});
    const [selectedPlace2, setSelectedPlace2] = useState(place2 || {});
    const [filter, setFilter] = useState({
        from_address: selectedPlace1,
        to_address: selectedPlace2
    });
    console.log(selectedPlace1, place2)
    const handlePlaceSelection1 = (place) => {
        setSelectedPlace1(place);
        setFilter({ ...filter, from_address: place });
    };

    const handlePlaceSelection2 = (place) => {
        setSelectedPlace2(place);
        setFilter({ ...filter, to_address: place });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log('Voir Fetch data')
                let url = `${process.env.REACT_APP_SERVER}/trajets`;
                if (filter.from_address || filter.to_address) {
                    url = url + `?filter=${encodeURIComponent(JSON.stringify(filter))}`;
                }
                const trajetResponse = await axios.get(url);
                console.log(trajetResponse.data)
                const trajetData = trajetResponse.data;
                const promises = trajetData.map(async (TrajetItem) => {
                    try {
                        const colisResponse = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${TrajetItem._id}`);
                        TrajetItem.colis = colisResponse.data ?? [];
                        return TrajetItem;
                    } catch (error) {
                        console.error("Error fetching colis and offres data:", error);
                        return null;
                    }
                });

                const updatedTrajetsData = await Promise.all(promises);

                // Filter out the null values representing failed requests
                const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);

                // Now filteredTrajetsData contains only successfully fetched data
                console.log(filteredTrajetsData)
                setTrajets(filteredTrajetsData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [filter]);

    return (
        <>
            <Helmet>
                <title>Voir colis</title>
                <link rel="canonical" href="https://www.colispo.com/voirColis" />
                <meta name="description" content="Voir les colis disponible pour tranporter." />
            </Helmet>
            <div className={`${classes.sidebar} z-2 position-absolute p-3`}>
                <Container>
                    <Row className="mb-3">
                        <Col>
                            <Mapinput firstValue={selectedPlace1?.name} onChange={handlePlaceSelection1} placeholder={"Ville de départ"} />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Mapinput firstValue={selectedPlace2?.name} onChange={handlePlaceSelection2} placeholder={"Ville d'arrivée"} />
                        </Col>
                    </Row>
                    {trajets.map((trajet) => (
                        <TrajetCard trajet={trajet} />
                    )
                    )}
                </Container>
            </div >
            <div style={{ height: "100vh", overflow: "auto" }} className="z-1 position-relative rounded-3">
                {/* Pass trajets data to the Map component */}
                <Map data={trajets} />
            </div>
        </>
    );
};

export default VoirColisDispo;
