import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthProvider from 'react-auth-kit/AuthProvider';
import createStore from 'react-auth-kit/createStore';
import refresh from './refresh';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
const store = createStore({
  authType: 'window.localStorage',
  authName: "_auth",
  cookieDomain: window.location.hostname,
  cookieSecure: false,
  refresh: refresh
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  /* <React.StrictMode>*/
  <AuthProvider store={store} >
    <App />
  </AuthProvider>
  /*  </React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals() 