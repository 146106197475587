import React, { useState, useEffect, useRef } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import PaginationComponent from "../../../components/paginations/pagination";
import fetchImage from "../../../components/fetchImg";
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";

function Sliders() {
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState()
    const [sliders, setSliders] = useState([]);
    const [imageFile, setImageFile] = useState([]); // Initialize imageFile as an empty array
    const [sliderId, setSliderId] = useState();
    const modalRef = useRef(null)
    const columns = [
        ["date de création", "date_creation"],
        ["chemin", "path"],
        ["photo", "photo"],
        ["opération", "operations"]
    ];
    const handleFileInputChange = async (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const file = files[0];
            const newImageFile = {
                file,
                imageUrl: URL.createObjectURL(file)
            };
            const updatedImageFile = [...imageFile, newImageFile];
            setImageFile(updatedImageFile);
        }
    };
    const openModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    };
    const closeModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance.hide()
    }
    const fetchCount = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/sliders/count`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchData = async (page) => {
        try {
            console.log("fetching data")
            const slidersResponse = await axios.get(`${process.env.REACT_APP_SERVER}/sliders?page=${page}`);
            const slidersData = slidersResponse.data;
            console.log(slidersData)


            const filteredslidersData = slidersData.filter(item => item !== null);
            console.log(filteredslidersData)
            setSliders(filteredslidersData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        fetchCount();
        fetchData();
    }, []);
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            console.log(imageFile);
            let formData = new FormData();
            formData.append('file', imageFile[0].file);

            try {
                const res1 = await axios.post(`${process.env.REACT_APP_SERVER}/sliders`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                console.log(res1.data);
                if (res1.status === 200) {
                    setImageFile([]); // Reset imageFile to an empty array
                    fetchData()
                    console.log("Sliders ajouté avec succès")
                }
            } catch (err) {
                console.error("ERROR", err.message, imageFile);
                throw err; // If an error occurs, throw it to be caught in the outer catch block
            }


        } catch (error) {
            console.log(error);
        }
    }
    const handleCheckboxChange = async (index) => {
        try {
            const row = sliders[index]
            console.log(row)
            row.active = !row.active
            await axios.put(`${process.env.REACT_APP_SERVER}/sliders/${row._id}`, { active: row.active }).then((response) => {
                fetchData()
            })
        } catch (err) {
            console.log(err)
        }
    }
    const handleDelete = async () => {
        try {
            console.log(`Deleting sliders ${sliderId}`);
            const response = await axios.delete(`${process.env.REACT_APP_SERVER}/sliders/${sliderId}`);
            if (response.status === 200) {
                fetchData()
                console.log("Sliders supprimé avec succès")
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <Row style={{ maxWidth: "100%" }} >
            <div className="mb-3 mt-1 w-100 p-0">
                <button className="btn btn-primary w-100" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    Ajouter un sliders
                </button>
                <div className="collapse" id="collapseExample">
                    <div className="card card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <div className="w-75">
                                    <input id={`photo`} className="form-control" type="file" onChange={handleFileInputChange} />
                                </div>
                                <button type="submit" className="btn btn-outline-primary w-25 text-nowrap">Ajouter</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Card className="Card" style={{ maxWidth: "100%" }}  >
                <CardHeader className="CardHeader">
                    <CardTitle tag="h4">Liste des sliders</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                    <div className="accordion" id="accordionExample">
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                <tr>
                                    {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {sliders.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <tr >
                                            {columns.map(column => (
                                                <td key={column[1]} className=" align-middle">
                                                    {column[1] === "operations" ? (
                                                        <div className="d-flex align-items-center">
                                                            <button className="btn btn-outline-danger btn-sm col-7 me-4"
                                                                onClick={() => {
                                                                    setSliderId(row._id)
                                                                    openModal(modalRef)
                                                                }}>Supprimer</button>
                                                            <div className="form-check form-switch ">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    role="switch"
                                                                    id={`flexSwitchCheckChecked-${row._id}`}
                                                                    checked={row.active}
                                                                    onChange={() => handleCheckboxChange(index)}
                                                                />
                                                                <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${row.id}`}>
                                                                    {row.active ? 'Activé' : 'Désactivé'}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ) : column[1] === "photo" ? (
                                                        <div>
                                                            <img src={fetchImage(row.path)} style={{ maxHeight: "40px" }} alt="Coli Image" />
                                                        </div>
                                                    ) : column[1] === "date_creation" ? (
                                                        new Date(row[column[1]]).toLocaleDateString('fr-FR', {
                                                            month: 'long',
                                                            day: 'numeric',
                                                            year: 'numeric'
                                                        })
                                                    ) : (
                                                        row[column[1]] || "Introuvable"
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <PaginationComponent page={page} lastPage={lastPage} setPage={setPage} fetchData={fetchData} />

                </CardBody>
            </Card>
            <div className="modal fade" ref={modalRef} tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true"            >
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTitleId">
                                Supprimer slider
                            </h5>
                            <button type="button" className="btn-close" onClick={() => { closeModal(modalRef) }} aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-danger" role="alert" >
                                vous êtes sûr de vouloir supprimer cette slider ?
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary w-auto" onClick={() => { closeModal(modalRef) }}>
                                Annuler
                            </button>
                            <button type="button" className="btn btn-outline-danger w-auto" data-bs-dismiss="modal"
                                onClick={() => {
                                    closeModal(modalRef)
                                    handleDelete()
                                }}>
                                Supprimer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Row>
    );
}
export default Sliders;
