import axios from "axios";

const fetchImage = (url) => {
    return `${process.env.REACT_APP_SERVER}/files?filePath=${url}`
    try {
        const response = axios.get(`${process.env.REACT_APP_SERVER}/files?filePath=${url}`, {
            headers: {
                'ngrok-skip-browser-warning': 'true',
            },
            responseType: 'blob', // Ensures the response is treated as an image
        });

        // Convert the blob to a URL
        const imageUrl = URL.createObjectURL(response.data);
        return imageUrl
    } catch (error) {
        console.error('Error fetching the image:', error);
    }
};
export default fetchImage;