import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import classes from "./payement.module.css"
import dashed_line from "../../assets/dashed_line.svg"
import car from "../../assets/car.svg"
import fetchImage from "../../components/fetchImg";
function Paiement() {
    const location = useLocation();
    const row = location.state;
    const [tarif, setTarif] = useState(0)
    const colisCols = [
        ["qte", "qte"],
        ["image", 'photos'],
        ["title", "title"],
        ["width", "width"],
        ["height", "height"],
        ["length", "length"],
        ["weight", "weight"]
    ]
    const fetchTarif = async () => {
        axios.get(`${process.env.REACT_APP_SERVER}/tarif`).then((res) => {
            console.log(res.data)
            setTarif(res.data.value)
        })
    }
    useEffect(() => {
        console.log(row)
        fetchTarif()
    }, [])
    const payer = async () => {
        try {
            const id = row.offre._id;
            await axios.post(`${process.env.REACT_APP_SERVER}/payments/${id}`).then((res) => {
                const url = res.data.approvalUrl
                window.location.href = url; // Open the URL in the same tab
            });
        }
        catch (error) {
            console.error("Error accepting offre:", error);
        }
    }
    return (
        <div className="container my-5 h-100" >
            <div className="row m-0 h-100 p-4">
                <div className="col-md-7 col-12 align-self-center card p-4  border-0 shadow-sm">
                    <div className="row">
                        <div className="col-3">
                            <p className="fw-semibold" style={{ color: "#1f406d" }}>Départ</p>
                            <p className="fw-semibold">{row.from_address.name},  {row.from_address.address_components[row.from_address.address_components.length - 1].long_name}</p>
                        </div>
                        <div className="col-6 text-center align-self-center" >
                            <img className="" src={car} />

                            <img className={`${classes.img}`} src={dashed_line} />
                        </div>
                        <div className="col-3">
                            <p className="fw-semibold" style={{ color: "#1f406d" }}>Arrivé</p>
                            <p className="fw-semibold">{row.to_address.name}, {row.to_address.address_components[row.to_address.address_components.length - 1].long_name}</p>
                        </div>
                        <div className="col-12 d-flex">
                            <p className="fw-semibold" style={{ color: "#1f406d" }}>Date de départ : </p>
                            <p className="ms-3 fw-normal"> Le {new Date(row.go_time).toLocaleDateString('fr-FR', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            })}</p>
                        </div>
                        <div className="col-12 d-flex">
                            <p className="fw-semibold" style={{ color: "#1f406d" }}>Date d'arrivé : </p>
                            <p className="ms-3 fw-normal"> Le {new Date(row.arrive_time).toLocaleDateString('fr-FR', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            })}</p>
                        </div>
                    </div>
                    <h1 className="h3 fw-bold mb-4">Liste de colis</h1>
                    <div className={`${classes.tableContainer}`}>
                        <table className="table" >
                            <thead className="text-primary">
                                <tr>
                                    {colisCols.map(column => <th key={column[1]}>{column[0]}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {row.colis.map((colis) => (
                                    <tr key={colis._id}>
                                        {colisCols.map(column => (
                                            <td key={column[1]} >
                                                {column[1] === "photos" ? (
                                                    <img src={fetchImage(colis.photos[0])} style={{ maxHeight: "40px" }} alt="Coli" />

                                                ) : (
                                                    colis[column[1]] || "Introuvable"
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>
                <div className="col-md-5 col-12 ps-md-5 p-0  align-self-center">
                    <div className="">
                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">Service</th>
                                    <th scope="col" className="text-center">Prix</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Transporteur</td>
                                    <td className="text-center">
                                        <span className="fas fa-dollar-sign"></span>{row.offre.selectedOffre == "offre" ? row.offre.offre.prix : row.offre.counterOffre.prix}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Notre service {tarif}%</td>
                                    <td className="text-center">
                                        <span className="fas fa-dollar-sign"></span>{(row.offre.selectedOffre == "offre" ? row.offre.offre.prix : row.offre.counterOffre.prix) * tarif / 100}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Totale</td>
                                    <td className="text-center">
                                        <span className="fas fa-dollar-sign"></span>{(row.offre.selectedOffre == "offre" ? row.offre.offre.prix : row.offre.counterOffre.prix) + (row.offre.selectedOffre == "offre" ? row.offre.offre.prix : row.offre.counterOffre.prix) * tarif / 100}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mb-5">
                        </div>
                    </div>
                    <div className="">
                        <div className="form">
                            <div className="btn btn-success d-block h8" onClick={payer}>
                                Payer
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Paiement;
