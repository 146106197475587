function PaginationComponent({ page, lastPage, setPage, fetchData }) {
    return (
        <nav aria-label="Page navigation" >
            <ul className="pagination">
                <li onClick={() => {
                    if (page > 1) {
                        setPage(page - 1)
                        fetchData(page - 1)
                    }
                }} className={`page-item mx-auto me-0 ${page === 1 ? "disabled" : ""}`}>
                    <a className="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                {/* Always show the first page */}
                <li onClick={() => {
                    setPage(1)
                    fetchData(1)
                }} className={`page-item ${page === 1 ? "active" : ""}`} aria-current="page">
                    <a className="page-link" href="#">1</a>
                </li>

                {/* Show ellipsis if there are pages between the first page and the current page */}
                {page > 3 && (
                    <li className="page-item">
                        <a className="page-link disabled" href="#">...</a>
                    </li>
                )}

                {/* Show the previous page if the current page is not the first or second page */}
                {page > 2 && (
                    <li onClick={() => {
                        setPage(page - 1)
                        fetchData(page - 1)
                    }} className="page-item">
                        <a className="page-link" href="#">{page - 1}</a>
                    </li>
                )}

                {/* Show the current page if it's not the first page */}
                {page !== 1 && page !== lastPage && (
                    <li className="page-item active" aria-current="page">
                        <a className="page-link" href="#">{page}</a>
                    </li>
                )}

                {/* Show the next page if the current page is not the last or second-to-last page */}
                {page < lastPage - 1 && (
                    <li onClick={() => {
                        setPage(page + 1)
                        fetchData(page + 1)
                    }} className="page-item">
                        <a className="page-link" href="#">{page + 1}</a>
                    </li>
                )}

                {/* Show ellipsis if there are pages between the current page and the last page */}
                {page < lastPage - 2 && (
                    <li className="page-item">
                        <a className="page-link disabled" href="#">...</a>
                    </li>
                )}

                {/* Always show the last page */}
                {lastPage > 1 && (
                    <li onClick={() => {
                        setPage(lastPage)
                        fetchData(lastPage)
                    }} className={`page-item ${page === lastPage ? "active" : ""}`} aria-current="page">
                        <a className="page-link" href="#">{lastPage}</a>
                    </li>
                )}
                <li onClick={() => {
                    console.log(page, lastPage)
                    if (page < lastPage) {
                        setPage(page + 1)
                        fetchData(page + 1)
                    }
                }} className={`page-item me-auto ${page === lastPage || lastPage === 0 ? "disabled" : ""}`}>
                    <a className="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}
export default PaginationComponent; 