import { useEffect, useRef, useState } from "react";
import PayPalLogin from "../../payement/addAcount";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import { Modal } from "bootstrap/dist/js/bootstrap.min";
import PaginationComponent from "../../../components/paginations/pagination";
function MesPaiements() {
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState()
    const [paiements, setPaiements] = useState([])
    const [accounts, setAccounts] = useState([])
    const [selectedPayment, setSelectedPayment] = useState(null)
    const modalRef = useRef(null)
    const modalRef1 = useRef(null)
    const modalRef2 = useRef(null)
    const modalRef3 = useRef(null)
    const token = window.localStorage.getItem("_auth");
    const [mode, setMode] = useState(null)
    const fetchCount = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/userPaiments/userCount`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchData = async () => {
        const eventSource = new EventSource(`${process.env.REACT_APP_SERVER}/userPaiments?token=${token}&page=${page}`);

        eventSource.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                console.log("Received event data:", data);

                // Use the latest selectedPayment value
                setSelectedPayment(prevSelectedPayment => {
                    if (prevSelectedPayment && data._id === prevSelectedPayment._id) {
                        console.log("Updating selected payment");
                        // Handle modal interactions based on payment state
                        if (data.etat === "payé") {
                            closeModal();
                            openPaymentSuccess();
                        } else if (data.etat === "refusé") {
                            closeModal();
                            openPaymentFailed();
                        }
                        return data; // Update the selectedPayment to the new data
                    }

                    // If selectedPayment doesn't match, return the previous value
                    return prevSelectedPayment;
                });

                // Update or add payment to the list
                setPaiements((prevPaiements) => {
                    const existingPaymentIndex = prevPaiements.findIndex((p) => p._id === data._id);
                    if (existingPaymentIndex !== -1) {
                        // Update the existing payment
                        const updatedPaiements = [...prevPaiements];
                        updatedPaiements[existingPaymentIndex] = data;
                        return updatedPaiements;
                    } else {
                        // Add the new payment
                        return [...prevPaiements, data];
                    }
                });
            } catch (error) {
                console.error("Error processing event data:", error);
            }
        };

        // Handle EventSource errors
        eventSource.onerror = (error) => {
            console.error("EventSource error:", error);
            eventSource.close();
        };

        // Cleanup EventSource on component unmount
        return () => {
            eventSource.close();
        };
    };



    const openPayModal = () => {
        const modal = new Modal(modalRef.current)
        modal.show()
    }
    const handlePayClick = async (paiement) => {
        console.log("setting selectedPayment", paiement)
        setSelectedPayment(paiement)
        await axios.get(`${process.env.REACT_APP_SERVER}/userPaiments/receive/${paiement._id}`).then(response => {
            console.log(response.data)
            openPayModal()
        }).catch(error => {
            console.log(error)
        })
    }
    const openAccountValidate = () => {
        const modal = new Modal(modalRef1.current)
        modal.show()
    }
    const openPaymentSuccess = () => {
        const modal = new Modal(modalRef2.current)
        modal.show()
    }
    const openPaymentFailed = () => {
        const modal = new Modal(modalRef3.current)
        modal.show()
    }
    const closeModal = () => {
        console.log("close")
        const modal = Modal.getInstance(modalRef.current)
        modal.hide()
    }
    const handlePay = async () => {
        if (mode === null) {
            openAccountValidate()
        }
        const payer = accounts[mode]
        console.log(payer)
        await axios.post(`${process.env.REACT_APP_SERVER}/payments/getPay/${selectedPayment._id}`, payer)
            .then(async response => {
            }).catch(error => {
                console.log(error)
            })
    }
    useEffect(() => {
        fetchCount();
        fetchData(page);
    }, []); // Only run once on mount

    return (
        <Row style={{ maxWidth: "100%" }} >
            <div className="mb-3 mt-1 w-100 p-0">
                <button className="btn btn-primary w-100" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    Gérer votre modes de paiement
                </button>
                <div className="collapse" id="collapseExample">
                    <Card className="Card col-12 mx-auto " style={{ maxWidth: "100%" }}  >
                        <CardBody className="pt-4">
                            <PayPalLogin accounts={accounts} setAccounts={setAccounts} />
                        </CardBody>
                    </Card>
                </div>
            </div>
            <Card className="Card" style={{ maxWidth: "100%" }}  >
                <CardHeader className="CardHeader">
                    <CardTitle tag="h4">Liste des paiements</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                    <div className="accordion" id="accordionExample">
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                <tr>
                                    <th>Date</th>
                                    <th>Montant</th>
                                    <th>Statut</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paiements.length > 0 && paiements.map((paiement, index) => (
                                    <tr key={index}>
                                        <td>{new Date(paiement.date_creation).toLocaleDateString()}</td>
                                        <td>{paiement.montant}</td>
                                        <td>{paiement.etat}</td>
                                        <td>
                                            {paiement.etat === "en attente" || paiement.etat === "refusé" ? (
                                                <button className="btn btn-outline-success btn-sm col-12" onClick={() => handlePayClick(paiement)}>Recupérer</button>
                                            ) : paiement.etat === "payé" ? (
                                                <button className="btn btn-outline-secondary btn-sm col-12" disabled>Aucune</button>
                                            ) : paiement.etat === "en cours" ? (
                                                <button className="btn btn-outline-secondary btn-sm col-12" disabled>Aucune</button>
                                            ) : (
                                                <button className="btn btn-outline-secondary btn-sm col-12" disabled>Aucune</button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
                <PaginationComponent page={page} lastPage={lastPage} setPage={setPage} fetchData={fetchData} />

            </Card>
            <div className="modal fade" id="modalId" ref={modalRef} tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTitleId">
                                Récupérer votre paiement
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h4> Sélectionnez votre compte</h4>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Email</th>
                                        <th>Nom</th>
                                        <th>Prénom</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        accounts.map((account, index) => (
                                            <tr key={index + 1}>
                                                <td><input type="radio" className="form-check-input my-auto" name="mode" checked={mode === index} value={index} onChange={() => setMode(index)} /></td>
                                                <td><p className="mb-0">{account.email_address}</p> </td>
                                                <td>{account.name.surname}</td>
                                                <td>{account.name.given_name}</td>
                                            </tr>
                                        ))
                                    }</tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-outline-secondary w-auto"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setMode(null); // Reset mode
                                }}
                            >
                                Annuler
                            </button>
                            {selectedPayment && selectedPayment.etat === "en cours" && (
                                <button className="btn btn-primary w-auto" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                    <span role="status">En cours...</span>
                                </button>
                            )}
                            {selectedPayment && (selectedPayment.etat === "en attente" || selectedPayment.etat === "refusé") && (
                                <button
                                    type="button"
                                    className="btn btn-success w-auto"
                                    onClick={handlePay}
                                >
                                    Terminer
                                </button>
                            )}
                        </div>

                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="modalId"
                tabIndex="-1"
                ref={modalRef1}
                data-bs-backdrop="static"
                data-bs-keyboard="false"

                role="dialog"
                aria-labelledby="modalTitleId"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTitleId">
                                Erreur de paiement
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div
                                className="alert alert-danger"
                                role="alert"
                            >
                                Veuillez sélectionner un compte pour effectuer le paiement
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-ouline-secondary w-auto"
                                data-bs-dismiss="modal"
                            >
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalId3" ref={modalRef2} tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
                <div
                    className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close ms-auto m-3"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-6 mx-auto my-4">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#198754" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <polyline className="path check" fill="none" stroke="#198754" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                    </svg>
                                </div>
                            </div>
                            <p className="mt-3 text-center">
                                Merci pour votre confiance. Votre paiement a été effectué avec succès.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modalId4" tabIndex="-1" ref={modalRef3} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
                <div
                    className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div className="modal-content">
                        <button type="button" className="btn-close ms-auto m-3" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-6 mx-auto my-4">
                                    <svg version="1.1" className="card-img-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#db3646" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <line className="path line" fill="none" stroke="#db3646" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                        <line className="path line" fill="none" stroke="#db3646" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="37.9" x2="34.4" y2="92.3" />
                                    </svg>
                                </div>
                            </div>
                            <p className="mt-3 text-center">
                                La transaction a échoué! Veuillez revenir à la page et réessayer ou contacter le support.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Row>

    );
}
export default MesPaiements;