import React, { useEffect, useState } from 'react';
import classes from "./home.module.css";
import Map from "../../components/map/map"
import cc1 from "../../assets/cc1.svg";
import cc2 from "../../assets/cc2.svg";
import cc3 from "../../assets/cc3.svg";
import etu1 from "../../assets/etu1.svg";
import etu2 from "../../assets/etu2.svg";
import etu3 from "../../assets/etu3.svg";
import Mapinput from '../../components/map/input';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TrajetCard from '../../components/cards/trajetCard/trajetCard';
import { Helmet } from 'react-helmet-async';
import fetchImage from '../../components/fetchImg';

function Home() {
    const [showFirstDiv, setShowFirstDiv] = useState({ display: "block" });
    const [showSecondDiv, setShowSecondDiv] = useState({ display: "none" });
    const [firstColor, setFirstColor] = useState('#1f406d');
    const [secondColor, setSecondColor] = useState('#white');
    const [selectedPlace1, setSelectedPlace1] = useState({ "name": "", "position": null });
    const [selectedPlace2, setSelectedPlace2] = useState({ "name": "", "position": null });
    const [trajets, setTrajets] = useState([]);
    const [ttrajets, setTtrajets] = useState([]);
    const [bgPosition, setBGPosition] = useState(0);
    const [sliders, setSliders] = useState([]);
    const navigate = useNavigate()
    useEffect(() => {
        const fetchTrajets = async () => {
            try {
                console.log('Voir Fetch data')
                let url = `${process.env.REACT_APP_SERVER}/trajets/samples`;
                const trajetResponse = await axios.get(url);
                const trajetData = trajetResponse.data;
                const promises = trajetData.map(async (TrajetItem) => {
                    try {
                        const colisResponse = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${TrajetItem._id}`);
                        TrajetItem.colis = colisResponse.data ?? [];
                        return TrajetItem;
                    } catch (error) {
                        console.error("Error fetching colis and offres data:", error);
                        return null;
                    }
                });

                const updatedTrajetsData = await Promise.all(promises);

                // Filter out the null values representing failed requests
                const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);

                // Now filteredTrajetsData contains only successfully fetched data
                console.log(filteredTrajetsData)
                setTrajets(filteredTrajetsData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        const fetchTtrajets = async () => {
            try {
                console.log('Voir Fetch data')
                let url = `${process.env.REACT_APP_SERVER}/t_trajets/samples`;
                const trajetResponse = await axios.get(url);
                const trajetData = trajetResponse.data;
                const promises = trajetData.map(async (TrajetItem) => {
                    try {
                        const userResponse = await axios.get(`${process.env.REACT_APP_SERVER}/users/${TrajetItem.transporteur}`);
                        TrajetItem.transporteur = userResponse.data ?? {};
                        return TrajetItem;
                    } catch (error) {
                        console.error("Error fetching colis and offres data:", error);
                        return null;
                    }
                });
                const updatedTrajetsData = await Promise.all(promises);

                // Filter out the null values representing failed requests
                const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);
                console.log(filteredTrajetsData)
                setTtrajets(filteredTrajetsData);
            } catch (error) {
                console.error("Error fetching data:", error);

            }
        }
        const fetchSliders = async () => {
            try {
                console.log('Voir Fetch data')
                let url = `${process.env.REACT_APP_SERVER}/sliders/active`;
                const sliderResponse = await axios.get(url);
                const sliderData = sliderResponse.data;
                console.log(sliderData)
                setSliders(sliderData);
            }
            catch (error) {
                console.error("Error fetching data:", error);

            }
        }
        fetchTrajets();
        fetchTtrajets();
        fetchSliders();
    }, []);

    const handlePlaceSelection1 = (place) => {
        setSelectedPlace1(place);
        console.log(place)
    };
    const handlePlaceSelection2 = (place) => {
        setSelectedPlace2(place);
        console.log(place)
    };
    const showFirst = () => {
        setShowFirstDiv({ display: "block" });
        setShowSecondDiv({ display: "none" });

        setSecondColor('white');
        setFirstColor('#1f406d');
        setBGPosition(0);
    };
    //'white'  '#1f406d'
    const showSecond = () => {
        setShowSecondDiv({ display: "block" });
        setShowFirstDiv({ display: "none    " });

        setFirstColor('white');
        setSecondColor('#1f406d');
        setBGPosition(50);
    };
    const handleTrajetSubmit = (e) => {
        e.preventDefault();
        console.log(selectedPlace1, selectedPlace2)
        navigate('/voirColis', { state: { place1: selectedPlace1, place2: selectedPlace2 } })
    }

    return (
        <>
            <Helmet>
                <title>Acceuil</title>
                <meta name="description" content="Colispo est la première plateforme au monde dédiée au covoiturage de colis entre particuliers. Notre service met en relation des personnes souhaitant expédier des colis à moindre coût et des conducteurs prêts à transporter ces envois lors de leurs trajets." />
                <meta name="keywords" content={`${process.env.REACT_APP_KEYWORDS}`} />
            </Helmet>
            <div className={`${classes.body}`}>
                <div className={`${classes.firstContainer} mt-0`}>
                    <div id="carouselExampleAutoplaying" className={`carousel slide carousel-fade ${classes.carouselHeight} `} data-bs-ride="carousel">
                        <div className="carousel-inner">
                            {sliders.map((slider, index) => (
                                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                    <img src={fetchImage(slider.path)} className="d-block w-100" />
                                </div>
                            ))}

                        </div>

                    </div>
                    <div className={`position-absolute start-50 translate-middle z-1 rounded  p-3 w-100 ${classes.containerTop}`}>
                        <p className={`${classes.t1} fs-1 fw-bold `}>Transporteur nécessaire ?</p>
                        <p className={`${classes.t2} fs-1 fw-bold `}>Pouvez-vous devenir transporteur ?</p>
                    </div>
                    <div className={`${classes.container} w-75 position-absolute start-50 translate-middle z-2  `}>
                        <div className={`${classes.button_container} py-3 bg-white `} >
                            <button onClick={showFirst} style={{ color: secondColor }} className='bg-transparent '>
                                <p className='d-md-none p-0 m-0'>Transportez</p>
                                <p className='d-none d-md-block p-0 m-0'>Chercher un trajet</p>
                            </button>
                            <button onClick={showSecond} style={{ color: firstColor }} className='bg-transparent '>
                                <p className='d-md-none p-0 m-0'>Expédiez</p>
                                <p className='d-none d-md-block p-0 m-0'>Expédier votre colis</p>
                            </button>
                            <div
                                className="position-absolute z-n1 top-0 start-0 p-2 "
                                style={{
                                    transition: 'transform 0.5s ease',
                                    transform: bgPosition === 0 ? 'translateX(0%)' : 'translateX(100%)',
                                    width: '50%',
                                    height: '100%'
                                }}
                            >
                                <div style={{ backgroundColor: '#1f406d', height: "100%" }} className='rounded-pill'></div>
                            </div>
                        </div>
                        <form style={showSecondDiv} className={`position-relative ${classes.form_container} p-2 px-md-3 pb-md-3 pt-0`} action='/ajouterColis'>
                            <div className='row w-100 mx-0'>
                                <div className="col-4 col-md-2 p-0"/*{`${classes.input_container}`}*/>
                                    <label htmlFor="">Quantité</label>
                                    <input placeholder="Qte" className='form-control' type="number" name='qte' />
                                </div>
                                <div className="col-8 col-md-3 pe-0 pe-md-2"/*{`${classes.input_container}`}*/>
                                    <label htmlFor="">Nom de l'objet</label>
                                    <input placeholder="Smartphone" className='form-control' type="text" name="title" />
                                </div>
                                <div className="col-12 col-md-5 p-0"/*{`${classes.input placeholder="Saisir"s_container}`}*/>
                                    <div className='row m-0 p-0'>
                                        <div className="col-4 ps-0"/*{`${classes.input placeholder="Saisir"_container}`}*/>
                                            <label htmlFor="" className='d-block d-sm-none'>Long</label>
                                            <label htmlFor="" className='d-none d-sm-block'>Longueur </label>
                                            <input placeholder="cm" className='form-control' type="number" name='longueur' />
                                        </div>
                                        <div className="col-4 ps-0"/*{`${classes.input placeholder="Saisir"_container}`}*/>
                                            <label htmlFor="" className='d-block d-sm-none'>Larg</label>
                                            <label htmlFor="" className='d-none d-sm-block'>Largeur</label>
                                            <input placeholder="cm" className='form-control' type="number" name='largeur' />
                                        </div>
                                        <div className="col-4 px-0 pe-md-2"/*{`${classes.input placeholder="Saisir"_container}`}*/>

                                            <label htmlFor="" className='d-block d-sm-none'>Haut</label>
                                            <label htmlFor="" className='d-none d-sm-block'>Hauteur</label>
                                            <input placeholder="cm" className='form-control' type="number" name="hauteur" />
                                        </div>
                                    </div>

                                </div>
                                <div className='col-12 mt-2 mt-md-0 col-md-2 align-self-end p-0'>
                                    <button className={`btn  ${classes.form_button} col-12`} type="submit" >Suivant</button>
                                </div>
                            </div>
                        </form>
                        <form style={showFirstDiv} className={`position-relative  ${classes.form_container} p-2 px-md-3 pb-md-3 pt-0`} action='/voirColis' onSubmit={handleTrajetSubmit}>
                            <div className='row'>
                                <div className={`col-6 col-md-3`}>
                                    <label htmlFor="">Ville de départ</label>
                                    <Mapinput onChange={handlePlaceSelection1} placeholder={"Ville de départ"} />
                                </div>
                                <div className={`col-6 col-md-3`}>
                                    <label htmlFor="">Ville d'arrivée</label>
                                    <Mapinput onChange={handlePlaceSelection2} placeholder={"Ville d'arrivée"} />
                                </div>
                                <div className='col-12 col-md-3'>
                                    <label htmlFor="" className='text-nowrap'>Date et heure de départ</label>
                                    <input className='form-control' type="datetime-local" name='date' />
                                </div>
                                <div className='col-12 mt-2 mt-md-0 col-md-3 justify-content-bottom align-self-end'>
                                    <button className={`btn  ${classes.form_button} col-12 `} value={"submit2"}>Chercher</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div style={{ backgroundColor: "#1f406d" }} className="text-light mb-0">
                    <h2
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseExample"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        className={`text-light text-center p-4 position-relative mb-0 ${classes.collapseHeading}`}
                    >
                        Comment ça marche ?
                        <i >
                            <svg className={`ms-2 ${classes.arrowIcon} `} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M41.5 18.6458L27.9167 32.2291C26.3125 33.8333 23.6875 33.8333 22.0833 32.2291L8.5 18.6458" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </i>
                    </h2>
                    <div className="collapse" id="collapseExample" style={{ backgroundColor: "#3f5064", height: '100%' }}>
                        <div className=" d-flex flex-column flex-md-row align-items-center container-md " >
                            <div className="col-12 col-md-4 col-lg-3 nav me-3 align-self-stretch text-start d-flex flex-row flex-md-column justify-content-center align-items-stretch" id="v-pills-tab" aria-orientation="vertical">
                                <div className={`${classes.ccbutton1} col-4 col-md-12 d-flex`}>
                                    <a className="h-100 btn btn-outline-light d-flex align-items-center justify-content-start border-0 rounded-0 fw-bold text-start active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" aria-controls="v-pills-home" aria-selected="true">
                                        Postuler votre annonce
                                    </a>
                                </div>
                                <div className={`${classes.ccbutton2} col-4 col-md-12 d-flex`}>
                                    <a className="h-100 btn btn-outline-light d-flex align-items-center justify-content-start border-0 rounded-0 fw-bold text-start" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                        Demander à un particulier
                                    </a>
                                </div>
                                <div className={`${classes.ccbutton3} col-4 col-md-12 d-flex`}>
                                    <a className="h-100 btn btn-outline-light d-flex align-items-center justify-content-start border-0 rounded-0 fw-bold text-start" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                        Transporter un colis
                                    </a>
                                </div>
                            </div>
                            <div className="tab-content p-0 col-12 col-md-8 col-lg-9  h-100" id="v-pills-tabContent" >
                                <div className="tab-pane show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                                    <div className='d-flex align-items-center '>
                                        <img className={`${classes.ccsvg}`} src={cc1} />
                                    </div>
                                </div>
                                <div className="tab-pane" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="0">
                                    <div className='d-flex'>
                                        <img className={`${classes.ccsvg}`} src={cc2} />

                                    </div>
                                </div>
                                <div className="tab-pane" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="0">
                                    <div className='d-flex'>
                                        <img className={`${classes.ccsvg}`} src={cc3} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div className='container'>
                    <div className={`${classes.secondContainer} row my-4 rounded-5 position-relative`}>
                        <div className='container position-relative z-1'>
                            <div className='row p-4 '>
                                <div className='col-12 col-lg-6 p-0 p-3'>
                                    <div className='position-relative'>
                                        <div >
                                            <Map style={"small"} />
                                            <div className='position-absolute top-100 start-50 translate-middle'>
                                                <a className="btn text-light btn-lg w-100 rounded-pill " style={{ backgroundColor: "#d8a942" }} href='/voirColis'>Voir tous les trajets</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className=' col-12 col-lg-6 p-0 mt-3 ps-md-5'>
                                    <div className="p-3">
                                        <div className='row '>
                                            <p className='fw-bold fs-1' style={{ color: "#1f406d" }}>Envoyer un colis avec Colispo, comment ça marche ?</p>
                                        </div>
                                        <div className='d-flex flex-nowrap mt-5'>
                                            <div className='' >
                                                <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M91.6668 40.625C89.9585 40.625 88.5418 39.2084 88.5418 37.5V29.1667C88.5418 18.4167 81.5835 11.4584 70.8335 11.4584H29.1668C18.4168 11.4584 11.4585 18.4167 11.4585 29.1667V37.5C11.4585 39.2084 10.0418 40.625 8.3335 40.625C6.62516 40.625 5.2085 39.2084 5.2085 37.5V29.1667C5.2085 14.8334 14.8335 5.20837 29.1668 5.20837H70.8335C85.1668 5.20837 94.7918 14.8334 94.7918 29.1667V37.5C94.7918 39.2084 93.3752 40.625 91.6668 40.625Z" fill="#1f406d" />
                                                    <path d="M70.8335 94.7917H29.1668C14.8335 94.7917 5.2085 85.1667 5.2085 70.8333V62.5C5.2085 60.7917 6.62516 59.375 8.3335 59.375C10.0418 59.375 11.4585 60.7917 11.4585 62.5V70.8333C11.4585 81.5833 18.4168 88.5417 29.1668 88.5417H70.8335C81.5835 88.5417 88.5418 81.5833 88.5418 70.8333V62.5C88.5418 60.7917 89.9585 59.375 91.6668 59.375C93.3752 59.375 94.7918 60.7917 94.7918 62.5V70.8333C94.7918 85.1667 85.1668 94.7917 70.8335 94.7917Z" fill="#1f406d" />
                                                    <path d="M50.0005 54.4998C49.4589 54.4998 48.9172 54.3746 48.4172 54.083L26.3339 41.333C24.8339 40.458 24.3339 38.5415 25.2089 37.0415C26.0839 35.5415 28.0006 35.0414 29.4589 35.9164L49.9589 47.7913L70.3339 35.9998C71.8339 35.1248 73.7505 35.6663 74.5838 37.1246C75.4588 38.6246 74.9172 40.5414 73.4589 41.4164L51.5422 54.083C51.0838 54.333 50.5422 54.4998 50.0005 54.4998Z" fill="#1f406d" />
                                                    <path d="M50 77.1664C48.2917 77.1664 46.875 75.7498 46.875 74.0414V51.3748C46.875 49.6664 48.2917 48.2498 50 48.2498C51.7083 48.2498 53.125 49.6664 53.125 51.3748V74.0414C53.125 75.7498 51.7083 77.1664 50 77.1664Z" fill="#1f406d" />
                                                    <path d="M50.001 78.1242C47.5843 78.1242 45.2093 77.5826 43.2926 76.5409L29.9593 69.1244C25.9593 66.916 22.876 61.6243 22.876 57.0409V42.9159C22.876 38.3742 26.001 33.0827 29.9593 30.8327L43.2926 23.4159C47.126 21.2909 52.876 21.2909 56.7093 23.4159L70.0426 30.8327C74.0426 33.041 77.1259 38.3325 77.1259 42.9159V57.0409C77.1259 61.5826 74.0009 66.8744 70.0426 69.1244L56.7093 76.5409C54.7926 77.5826 52.4176 78.1242 50.001 78.1242ZM46.3343 28.9159L33.0009 36.3325C30.9593 37.4575 29.126 40.6242 29.126 42.9159V57.0409C29.126 59.3743 30.9593 62.4993 33.0009 63.6243L46.3343 71.0409C48.2509 72.0825 51.751 72.0825 53.6676 71.0409L67.001 63.6243C69.0426 62.4993 70.8759 59.3326 70.8759 57.0409V42.9159C70.8759 40.5825 69.0426 37.4575 67.001 36.3325L53.6676 28.9159C51.751 27.8743 48.2509 27.8743 46.3343 28.9159Z" fill="#1f406d" />
                                                </svg>
                                            </div>
                                            <div className=' ms-4' >
                                                <p className='fw-bold fs-3 mb-0' style={{ color: "#1f406d" }}>C’est très facile</p>
                                                <p className='fw-medium fs-5' style={{ color: "#1f406d" }}>Que vous soyez expéditeur ou destinataire, déposez une annonce avec les caractéristiques du colis à envoyer et acceptez l'une des offres reçues.</p>

                                            </div>
                                        </div>
                                        <div className='d-flex flex-nowrap mt-5'>
                                            <div className='' >
                                                <svg width="100" height="106" viewBox="0 0 100 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M83.3335 53.4931V60.9474C83.3335 74.7591 75.8335 80.6785 64.5835 80.6785H27.0835C15.8335 80.6785 8.3335 74.7591 8.3335 60.9474V37.2699C8.3335 23.4581 15.8335 17.5388 27.0835 17.5388H38.3335C37.7918 19.205 37.5002 21.0466 37.5002 23.0197V40.1201C37.5002 44.3732 38.8335 47.9686 41.2085 50.4679C43.5835 52.9672 47.0002 54.3703 51.0418 54.3703V60.4651C51.0418 62.7013 53.4585 64.0602 55.2502 62.8325L67.2918 54.3703H78.1252C80.0002 54.3703 81.7502 54.0631 83.3335 53.4931Z" stroke="#1f406d" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M91.6667 23.0197V40.12C91.6667 46.6532 88.5 51.5638 83.3333 53.493C81.75 54.063 80 54.3703 78.125 54.3703H67.2917L55.25 62.8324C53.4583 64.0602 51.0417 62.7013 51.0417 60.4651V54.3703C47 54.3703 43.5833 52.9671 41.2083 50.4679C38.8333 47.9686 37.5 44.3732 37.5 40.12V23.0197C37.5 21.0465 37.7917 19.205 38.3333 17.5388C40.1667 12.1018 44.8333 8.76941 51.0417 8.76941H78.125C86.25 8.76941 91.6667 14.4695 91.6667 23.0197Z" stroke="#1f406d" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M30.8335 96.4631H60.8335" stroke="#1f406d" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M45.8335 80.6785V96.4632" stroke="#1f406d" strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M77.0646 31.789H77.1021" stroke="#1f406d" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M65.3986 31.789H65.4361" stroke="#1f406d" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M53.7306 31.789H53.7681" stroke="#1f406d" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                            </div>
                                            <div className='ms-4' >
                                                <p className='fw-bold fs-3 mb-0' style={{ color: "#1f406d" }}>En temps réel</p>
                                                <p className='fw-medium fs-5' style={{ color: "#1f406d" }}>Colispo connecte les annonceurs avec les particuliers & transporteurs qui font le trajet. Mettez-vous d’accord sur les modalités du transport.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`position-absolute z-0 top-0 ${classes.firstCube} `}>
                        </div>
                        <div className={`position-absolute z-0 bottom-0 end-0 ${classes.secondCube} `}>
                        </div>
                    </div>
                </div>

                <div className={`${classes.thirdContainer} row m-0 text-center w-100 p-4 justify-content-center`}>
                    <div className='col-12 col-md-3 m-4  p-4'>
                        <p style={{ color: "#FEBE00", fontSize: "24px", fontWeight: "bold", marginBottom: "0" }}>Prix négociables</p>
                        <p style={{ fontSize: "16px" }}>Les particuliers décident du prix correct pour expédier ou transporter les colis</p>
                    </div>
                    <div className='col-12 col-md-3 m-4  p-4'>
                        <p style={{ color: "#FEBE00", fontSize: "24px", fontWeight: "bold", marginBottom: "0" }}>Service 24/7</p>
                        <p style={{ fontSize: "16px" }}>Notre service offre la possibilité de faire des annonces et de transporter les colis à tout moment</p>

                    </div>
                    <div className='col-12 col-md-3 m-4  p-4'>
                        <p style={{ color: "#FEBE00", fontSize: "24px", fontWeight: "bold", marginBottom: "0" }}>Facile à utiliser</p>
                        <p style={{ fontSize: "16px" }}>Facilité d'ajouter votre colis ou de faire des offres pour transporter des colis</p>
                    </div>
                </div>


                <div className={`${classes.forthContainer} d-flex flex-column justify-content-center mt-4 text-center w-100`}>
                    <p className='fs-2' style={{ color: "#e7a03c" }}>Consultez les dernières voyages</p>
                    <div className=' row row-cols-1 row-cols-lg-4 g-4 justify-content-center w-100 mx-auto' style={{ "--bs-bg-opacity": ".5" }}>
                        {ttrajets.map((trajet) => (
                            <div className="col d-flex justify-content-center">
                                <div role="button" className="card bg-transparent border border-0 text-center " onClick={() => { navigate('/ajouterColis', { state: { trajet: trajet } }) }}>
                                    <img src={fetchImage(trajet.transporteur.profilePic)} className="card-img-top align-self-center rounded-circle" style={{ height: "160px", width: "160px", objectFit: "cover" }} alt="..." />
                                    <div className="card-body ">
                                        <h5 className="fw-light">
                                            {trajet.transporteur.prenom}
                                        </h5>
                                        <p className='m-0 fw-light card-text'>{trajet.from_address.name} <b>vers</b>  {trajet.to_address.name} </p>
                                    </div>
                                </div>

                            </div>

                        )

                        )}
                    </div >
                    <a className='btn col-12 col-md-6 text-light align-self-center fw-semibold my-4' style={{ backgroundColor: "#d8a942" }} href="/voirTrajets">Voir les dernières trajets</a>
                </div>

                <div style={{ backgroundColor: "#3f5064" }} className={`${classes.secondContainer} rounded-0 p-4`} >
                    <h2 className='fs-2 m-4 text-light text-center'>Consultez les dernières annonces</h2>
                    <div className=' row row-cols-1 row-cols-lg-2 g-4 p-md-4 pt-0 m-md-4 ' style={{ "--bs-bg-opacity": ".5" }}>
                        {trajets.map((trajet) => (
                            <TrajetCard trajet={trajet} />
                        )
                        )}
                    </div >
                    <div className="text-center">
                        <a className='btn  btn-outline-light btn-lg  fw-semibold mb-4' href="/voirColis">Voir les dernières annonces</a>

                    </div>

                </div>



            </div >
        </>)
}
export default Home