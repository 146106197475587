import React, { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Users from './pages/users';
import { useEffect } from 'react';
import classes from './dashboard.module.css'
import MesColis from './pages/mes_colis';
import MesLivraisons from './pages/mes_livraison';
import Colis from './pages/colis';
import Livraisons from './pages/livraisons';
import Sidebar from './components/sidebar/sidebar';
import RequireRole from '../../components/requireRole';
import { useLocation } from 'react-router-dom';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import Relais from './pages/relais';
import ModifierTarif from './pages/modifier-tarif';
import Admins from './pages/admins';
import OffresRecus from './pages/offres_recus';
import Sliders from './pages/sliders';
import Blogs from './pages/blogs';
import EditerBlog from './pages/editerBlog';
import MesPaiements from './pages/mes_paiements';
import Paiements from './pages/paiements';
import Reports from './pages/reports';

function Dashboard({ openChat }) {
    const navigate = useNavigate()
    const user = useAuthUser()
    const userRole = user.userRole

    const location = useLocation();
    const [full, setFull] = useState(false)
    const handleSetFull = () => {
        setFull(!full)
        console.log(full)
    }

    useEffect(() => {
        if (location.pathname === '/Dashboard') {
            console.log(user)

            switch (userRole) {
                case 'admin':
                    navigate('users');
                    break;
                case 'particulier':
                    navigate('mesColis');
                    break;
                case 'superadmin':
                    navigate('admins');
                    break;
                case 'transporteur':
                    navigate('mesLivraisons');
                    break;
                default:
                    break;
            }
        }
    }, [userRole, navigate, location]);
    return (

        <div className={` `}>
            <div className={``}>
                <Sidebar setFull={handleSetFull} />
            </div>
            <div className={``}>
                <div className={`${classes.content} `}>
                    <Routes>
                        <Route path="/users" element={<Users />} />
                        <Route path="/OffresRecus" element={<RequireRole roles={['admin', 'particulier']}><OffresRecus openChat={openChat} /></RequireRole>} />
                        <Route path="/mesColis" element={<RequireRole roles={['admin', 'particulier']}><MesColis openChat={openChat} /></RequireRole>} />
                        <Route path='/mesLivraisons' element={<RequireRole roles={['admin', 'particulier', 'transporteur']}><MesLivraisons openChat={openChat} /></RequireRole>} />
                        <Route path='/colis' element={<RequireRole roles={['admin']}><Colis /></RequireRole>} />
                        <Route path='/livraisons' element={<RequireRole roles={['admin']}><Livraisons /></RequireRole>} />
                        <Route path='/relais' element={<RequireRole roles={['superadmin']}><Relais /></RequireRole>} />
                        <Route path='/tarif' element={<RequireRole roles={['superadmin']}><ModifierTarif /></RequireRole>} />
                        <Route path='/admins' element={<RequireRole roles={['superadmin']}><Admins /></RequireRole>} />
                        <Route path='/paiements' element={<RequireRole roles={['superadmin', 'admin']}><Paiements /></RequireRole>} />
                        <Route path='/mesPaiements' element={<RequireRole roles={['particulier', 'transporteur']}><MesPaiements /></RequireRole>} />
                        <Route path='/sliders' element={<RequireRole roles={['superadmin']}><Sliders /></RequireRole>} />
                        <Route path='/blogs' element={<RequireRole roles={['superadmin']}><Blogs /></RequireRole>} />
                        <Route path='/editerBlog/:id' element={<RequireRole roles={['superadmin']}><EditerBlog /></RequireRole>} />
                        <Route path='/reclamations' element={<RequireRole roles={["admin"]}><Reports /></RequireRole>} />

                    </Routes>
                </div>
            </div>
        </div>

    )
}
export default Dashboard;