import React, { useEffect, useRef, useState } from "react";
import classes from "./ajouter-colis.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Ajouter_colis_form from "./ajouer-colis-form.js";
import Ajouter_trajet_form from "./ajouter-trajet-form.js";
import Ajouter_offre_form from "./ajouter-offre-form.js";
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";
import { Helmet } from "react-helmet-async";

function Ajouter_colis() {
    const initialColis = [{ id: Date.now(), title: undefined, qte: undefined, width: undefined, height: undefined, length: undefined, weight: undefined, photos: [] }];
    const initialUploadedFiles = [];
    const initialUploadedColis = [];
    const initialOffre = {};
    const modalRef = useRef(null);
    const modalRef2 = useRef(null);
    const modalRef3 = useRef(null);

    useEffect(() => {
        console.log("Bootstrap loaded:", window.bootstrap);
    }, []);

    const [colis, setColis] = useState(initialColis);
    const [uploadedFiles, setUploadedFiles] = useState(initialUploadedFiles);
    const [uploadedColis, setUploadedColis] = useState(initialUploadedColis);
    const [offre, setOffre] = useState(initialOffre);

    const [step, setStep] = useState(1);
    const [trajet, setTrajet] = useState({})
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.trajet) {
            setTrajet(location.state.trajet);
            setStep(2);
        }

        const params = new URLSearchParams(location.search);
        const title = params.get('title');
        const qte = params.get('qte');
        const width = params.get('largeur');
        const height = params.get('hauteur');
        const length = params.get('longueur');
        const weight = params.get('weight');
        const newColis = [...colis];
        newColis[0] = { ...newColis[0], title, qte, width, height, length, weight };
        setColis(newColis);

    }, [location]);
    const navigate = useNavigate();
    const addColi = (e) => {
        e.preventDefault()
        setColis(prevColis => [...prevColis, { id: Date.now(), title: null, qte: null, width: null, height: null, length: null, weight: null, photos: [] }]);
        console.log(colis)
    }
    const handleRemoveColi = async (coliIndex) => {
        setColis(prevColis => prevColis.filter((_, index) => index !== coliIndex));
    };
    const verify = () => {
        if (colis.length === 0) {
            return false;
        }
        for (let coli of colis) {
            if (!coli.title || coli.qte === undefined || coli.width === undefined || coli.height === undefined || coli.length === undefined || coli.weight === undefined || coli.photos.length === 0) {
                console.log(coli)
                return false;
            }
        }
        const goTime = new Date(trajet.go_time).getTime();
        const arriveTime = new Date(trajet.arrive_time).getTime();

        if (goTime > arriveTime) {
            return false;
        }
        return true
    }

    const submitTrajet = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_SERVER}/trajets`, trajet);
            console.log(res.data);
            return res.data;
        } catch (err) {
            console.error("Erreur lors de l'ajout du trajet", err);
            throw err;
        }
    };
    const submitColis = async (id) => {
        for (let i of colis) {
            console.log(i);

            // Initialize the coli object
            let coli = {
                title: i.title,
                qte: i.qte,
                width: i.width,
                height: i.height,
                length: i.length,
                weight: i.weight,
                photos: [] // Ensure photos array exists
            };

            console.log(trajet);
            if (!trajet.transporteur) {
                console.log("set trajet id");
                coli.trajet = id;
            }

            // Upload photos and get the paths
            let photos = [];
            console.log(i.photos);
            for (let j of i.photos) {
                console.log(j);
                let formData = new FormData();
                formData.append('file', j.file);

                try {
                    const res1 = await axios.post(`${process.env.REACT_APP_SERVER}/files`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                    console.log(res1.data);

                    if (res1.data.fileName) { // Ensure the file name/path exists in the response
                        const uploadedFilePath = res1.data.fileName; // Get the correct field from the response
                        photos.push(uploadedFilePath); // Push the uploaded file path to the array
                        uploadedFiles.push(uploadedFilePath); // Track uploaded files
                    } else {
                        throw new Error("File upload response does not contain the file path.");
                    }
                } catch (err) {
                    console.error("ERROR", err.message, j);
                    throw err; // If an error occurs, throw it to be caught in the outer catch block
                }
            }

            // Check if photos were uploaded successfully
            if (photos.length > 0) {
                coli.photos = photos; // Set the photos array in the coli object
                console.log(coli);

                try {
                    // Send the coli data including photos to the server
                    await axios.post(`${process.env.REACT_APP_SERVER}/colis`, coli)
                        .then((res) => {
                            console.log(res.data);
                            uploadedColis.push(res.data); // Track uploaded colis data
                        });

                } catch (err) {
                    throw err; // If an error occurs, throw it to be caught in the outer catch block
                }
            } else {
                throw new Error("No images uploaded for this colis."); // If no image is uploaded, throw an error
            }
        }
        if (id) {
            openModal(modalRef3);
        }
    };

    const submitOffre = async () => {
        try {
            const colisIds = uploadedColis.map(colis => colis._id);
            console.log(colisIds);
            let finalOffre = {};
            finalOffre.offre = offre;
            finalOffre.colis = colisIds;
            finalOffre.offre.prix = trajet.prix;
            finalOffre.ttrajet = trajet._id;
            console.log(finalOffre);
            await axios.post(`${process.env.REACT_APP_SERVER}/TtrajetOffres`, finalOffre).then((res) => {
                openModal(modalRef2);
            }).catch((err) => {
                if (err.response.status === 400 && err.response.data.error === 1) {
                    console.log("open modal ");
                    openModal(modalRef);
                }
            });
        } catch (err) {
            console.error("Erreur lors de l'ajout de l'offre", err.message);
            throw err;
        }
    };
    const handleSubmit = (e) => {
        console.log(colis)
        e.preventDefault();
        const form = e.currentTarget;
        if (!form.checkValidity() || !verify()) {
            e.stopPropagation();
            form.classList.add('was-validated');
            console.log('was-validated')
            return; // Prevent the step from incrementing
        }
        else {
            console.log(step)
            if (step < 3)
                setStep(prevStep => prevStep + 1);
            else
                try {
                    submit()
                    //navigate("/Dashboard/mesColis")
                } catch (err) {
                    throw err;
                }
        }
    };
    const deleteTrajet = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_SERVER}/trajets/${trajet._id}`);
        } catch (err) {
            console.error("Erreur lors de la suppression du trajet", err.message);
        }
    }
    const deleteColis = async () => {
        for (let coli of uploadedColis) {
            try {
                await axios.delete(`${process.env.REACT_APP_SERVER}/colis/${coli._id}`);
            } catch (deleteErr) {
                console.error("Failed to delete coli:", deleteErr);
            }
        }
    }
    const deleteOffre = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_SERVER}/TtrajetOffres/${offre._id}`);
        } catch (err) {
            console.error("Erreur lors de la suppression de l'offre", err.message);
        }
    }
    const deleteFiles = async () => {
        for (let filePath of uploadedFiles) {
            try {
                await axios.delete(`${process.env.REACT_APP_SERVER}/files/${filePath}`);
            } catch (deleteErr) {
                console.error(`Failed to delete file ${filePath}:`, deleteErr);
            }
        }
    }
    const submit = async () => {
        try {
            console.log("onSubmit1");
            console.log("trajet:", trajet);
            if (!trajet.transporteur || trajet.go_time < Date.now() || trajet.go_time > trajet.arrive_time) {
                console.log("1")
                let newTrajet = await submitTrajet();
                await submitColis(newTrajet._id);
                openModal(modalRef3);
            } else {
                console.log(trajet)
                console.log(offre)
                console.log("2")
                await submitColis();
                await submitOffre();
            }
            setColis(initialColis);
            setUploadedFiles(initialUploadedFiles);
            setUploadedColis(initialUploadedColis);
            setOffre(initialOffre);
        } catch (err) {
            deleteColis();
            deleteFiles();
            setUploadedColis(initialUploadedColis)
            setUploadedFiles(initialUploadedFiles)
            if (trajet.transporteur)
                deleteOffre();
            else
                deleteTrajet();
        }
        setTrajet({})
    }
    const openModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    };
    const closeModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance.hide();
    };
    return (
        <>
            <Helmet>
                <title>Ajouter colis</title>
                <link rel="canonical" href="https://www.colispo.com/ajouterColis" />
                <meta name="description" content="Ajouter votre colis pour recevoir les offres." />
            </Helmet>
            <form onSubmit={handleSubmit} className="form needs-validation row m-0" noValidate>

                <div className="row m-0">
                    <div className="col-12 col-md-8 col-lg-6 col-xxl-6 p-3 ">
                        {colis.map((coli, index) => (
                            step >= 1 && <div className='position-relative mb-3' key={coli.id}>
                                <Ajouter_colis_form coli={coli} index={index} setColis={setColis} />
                                <span onClick={() => { handleRemoveColi(index) }} className="position-absolute top-0 start-100 translate-middle mn-2">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.4" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="black" fillOpacity="0.88" />
                                        <path d="M13.0604 12.0004L15.3604 9.70035C15.6504 9.41035 15.6504 8.93035 15.3604 8.64035C15.0704 8.35035 14.5904 8.35035 14.3004 8.64035L12.0004 10.9404L9.70035 8.64035C9.41035 8.35035 8.93035 8.35035 8.64035 8.64035C8.35035 8.93035 8.35035 9.41035 8.64035 9.70035L10.9404 12.0004L8.64035 14.3004C8.35035 14.5904 8.35035 15.0704 8.64035 15.3604C8.79035 15.5104 8.98035 15.5804 9.17035 15.5804C9.36035 15.5804 9.55035 15.5104 9.70035 15.3604L12.0004 13.0604L14.3004 15.3604C14.4504 15.5104 14.6404 15.5804 14.8304 15.5804C15.0204 15.5804 15.2104 15.5104 15.3604 15.3604C15.6504 15.0704 15.6504 14.5904 15.3604 14.3004L13.0604 12.0004Z" fill="black" fillOpacity="0.88" />
                                    </svg>
                                </span>
                            </div>
                        ))}
                        <button className="btn btn-outline-primary col-12 fs-5 " onClick={addColi}>+</button>
                    </div>
                    <div className="col-12 col-lg-6 col-xxl-6 p-3 ">

                        <div className={`${classes.stickyTop1} row`}>

                            <div className="col-12 col-lg-12 col-xxl-12 mb-3">
                                <div className=" ">
                                    {step >= 2 && < Ajouter_trajet_form trajet={trajet} setTrajet={setTrajet} offre={offre} setOffre={setOffre} />}
                                </div>
                            </div>

                            <div className="col-12 col-xxl-12">
                                {step >= 3 && <Ajouter_offre_form trajet={trajet} setTrajet={setTrajet} />}
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary col-12 mt-3" type="submit">Suivant</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modalRef} tabIndex="-1" aria-labelledby="modal1Label" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modal1Label">Attention : Offre Non Soumise</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="alert alert-danger" role="alert">
                                    Vous n'avez pas encore soumis d'offre pour celle que vous avez créée.
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal 2 */}
                <div className="modal fade" ref={modalRef2} tabIndex="-1" aria-labelledby="modal2Label" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modal2Label">Succès : Offre Ajoutée</h5>
                            </div>
                            <div className="modal-body">
                                <div className="alert alert-success" role="alert">
                                    L'offre a été ajoutée avec succès ! Vous pouvez la consulter dans la section "Mes Colis Demandés".
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => {
                                    closeModal(modalRef2)
                                    navigate('/dashboard/mesColis')
                                }}>Voir le Dashboard</button>
                                <button type="button" className="btn btn-link" onClick={() => {
                                    closeModal(modalRef2)
                                    navigate('/')
                                }}>Retour à l'Accueil</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal 3 */}
                <div className="modal fade" ref={modalRef3} tabIndex="-1" aria-labelledby="modal3Label" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modal3Label">Succès : Annonce Ajoutée</h5>
                            </div>
                            <div className="modal-body">
                                <div className="alert alert-success" role="alert">
                                    Votre annonce a été ajoutée avec succès ! Rendez-vous dans le tableau de bord sous "Mes Colis Postulés" pour la consulter.
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => {
                                    closeModal(modalRef3)
                                    navigate('/dashboard/mesColis')
                                }}>Voir le Dashboard</button>
                                <button type="button" className="btn btn-link" onClick={() => {
                                    closeModal(modalRef3)
                                    navigate('/')
                                }}>Retour à l'Accueil</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form >
        </>
    );
}
export default Ajouter_colis;
