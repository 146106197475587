import React, { useRef, useState } from "react";

import InscriptionImg from "../../assets/home_inscription.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import classes from './style.module.css'
import { Modal } from "bootstrap/dist/js/bootstrap.min";

const Creercompte = () => {
    console.log('rendering creer compte')
    const [terms, setTerms] = useState(false)
    const modalRef = useRef(null);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        nom: '',
        prenom: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [touchedFields, setTouchedFields] = useState({});
    const [emailExistsError, setEmailExistsError] = useState(false);

    const validateField = (name, value) => {
        switch (name) {
            case 'nom':
            case 'prenom':
                return value.trim() !== '';
            case 'email':
                return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && !emailExistsError;
            case 'password':
                return value.length >= 6;
            case 'confirmPassword':
                return value === formData.password;
            default:
                return true;
        }
    };

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (name === "email") setEmailExistsError(false);  // Reset email error when editing
    };

    const onBlur = (e) => {
        const { name } = e.target;
        setTouchedFields({ ...touchedFields, [name]: true });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const isFormValid = Object.keys(formData).every((field) =>
            validateField(field, formData[field])
        );

        if (!isFormValid) {
            setTouchedFields({
                nom: true,
                prenom: true,
                email: true,
                password: true,
                confirmPassword: true
            });
            return;
        }

        axios.post(`${process.env.REACT_APP_SERVER}/users/register`, formData)
            .then((res) => {
                console.log(res);
                if (res.status === 201) {
                    openModal();
                }
                if (res.status === 200) {
                    navigate("/connection");
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setEmailExistsError(true);
                }
            });
    };

    // Function to open the modal
    const accept = () => {
        console.log(!terms)
        setTerms(!terms)
    }
    const openModal = () => {
        const modalElement = modalRef.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    };

    const closeModal = () => {
        const modalElement = modalRef.current;
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance.hide();
    };
    const resendCode = () => {
        axios.post(`${process.env.REACT_APP_SERVER}/users/resendCode`, { email: formData.email, password: formData.password })
            .then((res) => {
                console.log(res);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    return (
        <div className="bg-gradient-primary">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-12 col-xl-10">
                        <div className="card shadow-lg o-hidden border-0 my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-flex p-0">
                                        <div className="flex-grow-1 bg-login-image ">
                                            <img src={InscriptionImg} alt="connection" className="w-100 " />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 align-self-center">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h4 className="text-dark mb-4">Bienvenue!</h4>
                                            </div>
                                            <form className="user needs-validation" onSubmit={onSubmit} noValidate>
                                                <div className="row mb-3">
                                                    <div className="col-sm-6">
                                                        <input
                                                            className={`form-control form-control-user ${touchedFields.nom && !validateField('nom', formData.nom) ? `${classes.shake} is-invalid` : ''}`}
                                                            type="text"
                                                            placeholder="Nom"
                                                            name="nom"
                                                            value={formData.nom}
                                                            onChange={onInputChange}
                                                            onBlur={onBlur}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">
                                                            Veuillez entrer un nom.
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <input
                                                            className={`form-control form-control-user ${touchedFields.prenom && !validateField('prenom', formData.prenom) ? `${classes.shake} is-invalid` : ''}`}
                                                            type="text"
                                                            placeholder="Prénom"
                                                            name="prenom"
                                                            value={formData.prenom}
                                                            onChange={onInputChange}
                                                            onBlur={onBlur}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">
                                                            Veuillez entrer un prénom.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <input
                                                        className={`form-control form-control-user ${classes.email_input} ${emailExistsError || (touchedFields.email && !validateField('email', formData.email)) ? `${classes.shake} is-invalid` : ''}`}
                                                        type="email"
                                                        placeholder="Adresse Email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={onInputChange}
                                                        onBlur={onBlur}
                                                        required
                                                    />
                                                    <div className="invalid-feedback">
                                                        {emailExistsError ? "Cet email est déjà utilisé." : "Veuillez entrer une adresse email valide."}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                                        <input
                                                            className={`form-control form-control-user ${touchedFields.password && !validateField('password', formData.password) ? `${classes.shake} is-invalid` : ''}`}
                                                            type="password"
                                                            placeholder="Mot de passe"
                                                            name="password"
                                                            value={formData.password}
                                                            onChange={onInputChange}
                                                            onBlur={onBlur}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">
                                                            Le mot de passe doit comporter au moins 6 caractères.
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <input
                                                            className={`form-control form-control-user ${touchedFields.confirmPassword && !validateField('confirmPassword', formData.confirmPassword) ? `${classes.shake} is-invalid` : ''}`}
                                                            type="password"
                                                            placeholder="Confirmer le mot de passe"
                                                            name="confirmPassword"
                                                            value={formData.confirmPassword}
                                                            onChange={onInputChange}
                                                            onBlur={onBlur}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">
                                                            Les mots de passe ne correspondent pas.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-check mb-4">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={accept} />
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                        Accépter les <a className="link" data-bs-toggle="modal" data-bs-target="#modalCU" style={{ cursor: "pointer" }}>conditions d'utilisation</a> et <a className="link" data-bs-toggle="modal" data-bs-target="#modalPC" style={{ cursor: "pointer" }}>politique de confidentialité</a>
                                                    </label>
                                                </div>
                                                <button type="submit" className={`${!terms ? "disabled" : ""} btn btn-primary btn-user btn-block col-12`} >
                                                    Créer un compte
                                                </button>
                                            </form>
                                            <hr />
                                            <div className="text-center"><a className="small" href="connection">Connecter!</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" ref={modalRef} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Vérifiez votre email</h5>
                            <button type="button" className="btn-close" onClick={closeModal} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            un email est envoyé à votre adresse email pour vérifier votre compte
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary w-auto" onClick={resendCode}>Renvoyer</button>
                            <button type="button" className="btn btn-primary w-auto" onClick={() => {
                                closeModal()
                                navigate("/connection")
                            }}>Email reçu</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalPC" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">politique de confidentialité</h5>
                            <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal" >
                            </button>
                        </div>
                        <div className="modal-body">
                            condition d'utilisation
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary  w-auto" data-bs-dismiss="modal"  >Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modalCU" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Condition d'utilisation</h5>
                            <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal" >
                            </button>
                        </div>
                        <div className="modal-body">
                            condition d'utilisation
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary  w-auto" data-bs-dismiss="modal"  >Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

};

export default Creercompte;