import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';

const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
    const watchIdRef = useRef(null);
    const [isSharing, setIsSharing] = useState(false);
    const shareLocation = async () => {
        if (watchIdRef.current !== null) {
            // Stop watching the position if already active
            navigator.geolocation.clearWatch(watchIdRef.current);
            watchIdRef.current = null;
        }
        const options = {

        };
        watchIdRef.current = navigator.geolocation.watchPosition(
            async (position) => {
                console.log("Latitude:", position.coords.latitude);
                console.log("Longitude:", position.coords.longitude);

                const trackingData = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };

                try {
                    const response = await axios.post(`${process.env.REACT_APP_SERVER}/tracking`, trackingData);
                    console.log(response.data);
                } catch (error) {
                    console.error("Error sending location:", error);
                }
                setIsSharing(true); // Update state to indicate location sharing has started
                console.log("Location sharing started.");

            },
            (error) => {
                console.error("Geolocation error:", error);
            }, options
        );
    };

    const stopSharingLocation = () => {
        if (watchIdRef.current !== null) {
            navigator.geolocation.clearWatch(watchIdRef.current);
            watchIdRef.current = null;
            setIsSharing(false);  // Update state to indicate location sharing has stopped

            console.log("Location sharing stopped.");
        }
    };
    useEffect(() => {
        console.log('isSharing:', isSharing);
        if (isSharing) {
            window.localStorage.setItem('share', true);
            shareLocation()
        } else {
            window.localStorage.setItem('share', false);
            stopSharingLocation()
        }
    }, [isSharing]);
    useEffect(() => {
        const share = window.localStorage.getItem('share');
        console.log('isSharing:', share);
        setIsSharing(share === 'true');
    }, []);
    return (
        <LocationContext.Provider value={{ shareLocation, stopSharingLocation, isSharing, setIsSharing }}>
            {children}
        </LocationContext.Provider>
    );
};

export const useLocation = () => {
    return useContext(LocationContext);
};
