import { useJsApiLoader } from '@react-google-maps/api';
import React, { createContext, useContext, useRef, useState } from 'react';

const MapContext = createContext();

export const MapProvider = ({ children }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDS5UbPHEoKmTSGLtRLBzbSclyaV-lufcI',
        libraries: ["places"]
    })
    return (
        <MapContext.Provider value={{ isLoaded }}>
            {children}
        </MapContext.Provider>
    );
};

export const useMap = () => {
    return useContext(MapContext);
};
