import axios from 'axios';
import createRefresh from 'react-auth-kit/createRefresh';

const refresh = createRefresh({
    interval: 60,
    refreshApiCallback: async (param) => {

        try {
            console.log("Refreshing")
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/refresh`, param, {
                headers: { 'Authorization': `Bearer ${param.authToken}` }
            })
            console.log("Refreshing")
            return {
                isSuccess: true,
                newAuthToken: response.data.token,
                newRefreshToken: response.data.refreshToken,
                newAuthTokenExpireIn: 62,
                newRefreshTokenExpiresIn: 10080
            }
        }
        catch (error) {
            console.error(error)
            return {
                isSuccess: false
            }
        }
    }
})
export default refresh