import React, { useState, useEffect } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import PaginationComponent from "../../../components/paginations/pagination";
import ColisCollapse from "../../../components/colisCollapse/colisCollapse";
import style from "../style.module.css"

function Livraisons() {

    const [trajets, setTrajets] = useState([]);
    const [ttrajets, setTtrajets] = useState([]);


    const suivi = (type, index) => {
        try {
            let id
            if (type === "trajet") {
                id = trajets[index]._id
            } else if (type === "ttrajet") {

                id = ttrajets[index].offre._id
            }
            else
                return
        } catch (error) {
            console.error("Error updating data:", error);
        }
    }
    const columns = [["date de départ", "go_time"], ["addresse de départ", "from_address.name"], ["date d'arrivé", "arrive_time"], ["addresse d'arrivé", "to_address.name"], ["prix", "offre.prix"], ["etat", "etat"], ["Operations", "operations"]];
    const columns2 = [["date de départ", "go_time"], ["addresse de départ", "from_address.name"], ["date d'arrivé", "arrive_time"], ["addresse d'arrivé", "to_address.name"], ["prix", "offre.prix"], ["etat", "etat"], ["Operations", "operations"]];
    const [page1, setPage1] = useState(1)
    const [lastPage1, setLastPage1] = useState()
    const [page2, setPage2] = useState(1)
    const [lastPage2, setLastPage2] = useState()
    const fetchCount1 = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/trajets/livreCount`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage1(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchCount2 = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/TtrajetOffres/livreCount`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage2(calculatedLastPage);
            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchData1 = async (page) => {
        try {
            const trajetResponse = await axios.get(`${process.env.REACT_APP_SERVER}/trajets/AllLivres?page=${page}`);
            const trajetData = trajetResponse.data;
            console.log("fetched trajets", trajetData)
            const promises = trajetData.map(async (TrajetItem) => {
                try {
                    const colisResponse = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${TrajetItem._id}`);
                    const offreResponse = await axios.get(`${process.env.REACT_APP_SERVER}/offres/livre?trajet=${TrajetItem._id}`);
                    TrajetItem.colis = colisResponse.data ?? [];
                    TrajetItem.offre = offreResponse.data ?? [];
                    return TrajetItem;
                } catch (error) {
                    console.error("Error fetching colis and offres data:", error);
                    return null; // Returning null to indicate failure
                }
            });

            const updatedTrajetsData = await Promise.all(promises);
            const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);
            console.log("trajets ", filteredTrajetsData)
            setTrajets(filteredTrajetsData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchData2 = async (page) => {
        try {
            const trajetOffreResponse = await axios.get(`${process.env.REACT_APP_SERVER}/TtrajetOffres/AllLivre?page=${page}`);
            const trajetOffreData = trajetOffreResponse.data;
            const promises = trajetOffreData.map(async (TrajetOffreItem) => {
                try {
                    const trajetResponse = await axios.get(`${process.env.REACT_APP_SERVER}/t_trajets/${TrajetOffreItem.ttrajet}`);
                    const trajet = trajetResponse.data;
                    trajet.colis = [];
                    for (let coli of TrajetOffreItem.colis) {
                        await axios.get(`${process.env.REACT_APP_SERVER}/colis/${coli}`)
                            .then((res) => {
                                trajet.colis.push(res.data)
                            })
                    }
                    trajet.offre = TrajetOffreItem;
                    return trajet;
                } catch (error) {
                    console.error("Error fetching colis and offres data:", error);
                    return null; // Returning null to indicate failure
                }
            });

            const updatedTrajetsData = await Promise.all(promises);
            const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);
            console.log(filteredTrajetsData)
            setTtrajets(filteredTrajetsData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    useEffect(() => {
        fetchCount1();
        fetchCount2();
        fetchData1(page1);
        fetchData2(page2);
    }, []);
    // This function will redirect to another page
    let watchId;

    async function startDeliver1(index) {
        try {
            const id = trajets[index]._id;
            await axios.put(`${process.env.REACT_APP_SERVER}/offres/deliver/${id}`)
                .then((response) => {
                    trajets[index] = response.data;
                })
            if (watchId !== undefined) {
                // If we're already watching the position, stop watching it
                navigator.geolocation.clearWatch(watchId);
            }

            watchId = navigator.geolocation.watchPosition(
                async function (position) {
                    console.log("Latitude is :", position.coords.latitude);
                    console.log("Longitude is :", position.coords.longitude);

                    const trackingData = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };

                    try {
                        const response = await axios.post(`${process.env.REACT_APP_SERVER}/tracking`, trackingData);
                        console.log(response.data);
                    } catch (error) {
                        console.error("Error:", error);
                    }


                },
                function (error) {
                    console.error("Error Code = " + error.code + " - " + error.message);
                }
            );
        }
        catch (error) {
            console.error("Error updating data:", error);
        }
    }
    async function startDeliver2(index) {
        try {
            const id = ttrajets[index].offre._id;
            await axios.put(`${process.env.REACT_APP_SERVER}/TtrajetOffres/deliver/${id}`)
                .then((response) => {
                    trajets[index].etat = "en cours";
                })
            if (watchId !== undefined) {
                // If we're already watching the position, stop watching it
                navigator.geolocation.clearWatch(watchId);
            }

            watchId = navigator.geolocation.watchPosition(
                async function (position) {
                    console.log("Latitude is :", position.coords.latitude);
                    console.log("Longitude is :", position.coords.longitude);

                    const trackingData = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };

                    try {
                        const response = await axios.post(`${process.env.REACT_APP_SERVER}/tracking`, trackingData);
                        console.log(response.data);
                    } catch (error) {
                        console.error("Error:", error);
                    }


                },
                function (error) {
                    console.error("Error Code = " + error.code + " - " + error.message);
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
        }
    }

    return (
        <Row style={{ maxWidth: "100%" }}>
            <nav>
                <div className={`${style.navs} nav nav-tabs`} id="nav-tab" role="tablist">
                    <button className="nav-link w-50 active" id="nav-colis-tab" data-bs-toggle="tab" data-bs-target="#nav-colis" type="button" role="tab" aria-controls="nav-colis" aria-selected="true"> <h5 className="text-start fw-lighter m-0">Les colis</h5> </button>
                    <button className="nav-link w-50" id="nav-trajet-tab" data-bs-toggle="tab" data-bs-target="#nav-trajet" type="button" role="tab" aria-controls="nav-trajet" aria-selected="false"><h5 className="text-start fw-lighter m-0">Les trajets</h5> </button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-colis" role="tabpanel" aria-labelledby="nav-colis-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">Livraisons sur les colis</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion1">
                                    {trajets.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr  >
                                                {columns.map(column => (
                                                    <td key={column[1]} className=" align-middle border-bottom-0 pb-0">
                                                        {column[1] === "operations" ? (
                                                            <>
                                                                {row.offre.etat === "en cours" ? (

                                                                    <button className="btn btn-outline-danger btn-sm my-1 col-12" onClick={() => suivi("trajet", index)}>Suivi</button>

                                                                ) : (
                                                                    <button className="btn btn-outline-primary my-1 btn-sm col-12 disabled">Aucune</button>
                                                                )}
                                                            </>

                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : "Introuvable"
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : "Introuvable"
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR')
                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR')
                                                        ) : column[1] === "offre.prix" ? (
                                                            row.offre.prix ? row.offre.prix : "Introuvable"
                                                        ) : (
                                                            row[column[1]] || "Introuvable"
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <ColisCollapse colis={row.colis} colspan={columns.length} />
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>

                        </CardBody>
                        <PaginationComponent page={page1} lastPage={lastPage1} setPage={setPage1} fetchData={fetchData1} />

                    </Card>
                </div >
                <div className="tab-pane fade" id="nav-trajet" role="tabpanel" aria-labelledby="nav-trajet-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">Livraisons sur les trajets</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns2.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion2">
                                    {ttrajets.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr >
                                                {columns2.map(column => (
                                                    <td key={column[1]} className=" align-middle border-bottom-0 pb-0">
                                                        {column[1] === "operations" ? (
                                                            <>
                                                                {row.offre.etat === "en cours" ? (
                                                                    <button className="btn btn-outline-danger btn-sm my-1 col-12" onClick={() => suivi("ttrajet", index)}>Suivi</button>
                                                                ) : (
                                                                    <button className="btn btn-outline-primary my-1 btn-sm col-12 disabled">Aucune</button>
                                                                )}
                                                            </>
                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : "Introuvable"
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : "Introuvable"
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR')
                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR')
                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR')
                                                        ) : column[1] === "offre.prix" ? (
                                                            row.offre.prix ? row.offre.prix : "Introuvable"
                                                        ) : column[1] === "etat" ? (
                                                            row.offre.etat ? row.offre.etat : "Introuvable"
                                                        ) : (
                                                            row[column[1]] || "Introuvable"
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <ColisCollapse colis={row.colis} colspan={columns2.length} />
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                        <PaginationComponent page={page2} lastPage={lastPage2} setPage={setPage2} fetchData={fetchData2} />

                    </Card>
                </div>
            </div >
        </Row >
    )
}

export default Livraisons;
