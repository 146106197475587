import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Report() {
    const location = useLocation();
    const trajet = location.state;
    const reports = ["Publicité mensongère", "Activité frauduleuse", "Contenu inapproprié", "Spam", "Tarification trompeuse", "Préoccupations en matière de santé et de sécurité"]
    const [selectedReport, setSelectedReport] = useState()
    const handleChange = (event) => {
        try {
            console.log(event.target.value)
            setSelectedReport(event.target.value)
        } catch (err) {
            console.log(err)
        }
    }
    const navigate = useNavigate()
    const submit = async (event) => {
        try {
            console.log('submiting')
            let report = {
                subject: trajet._id,
                content: selectedReport,
                subjectType: trajet.transporteur ? "Ttrajet" : 'Trajet'
            }
            await axios.post(`${process.env.REACT_APP_SERVER}/reports`, report).then((res) => {
                navigate('/')
                console.log(res)
            })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-8 col-lg-6 mx-auto mt-4">
                    <h1 className="mb-4"> Signalez ce trajet pour ces raisons:</h1 >
                    <form className="form ">
                        <input type="radio" class="btn-check" name="options-base" onChange={handleChange} value={reports[0]} id="option5" autocomplete="off" />
                        <label class="btn btn-outline-secondary  mb-3" for="option5">{reports[0]}</label>

                        <input type="radio" class="btn-check" name="options-base" onChange={handleChange} value={reports[1]} id="option6" autocomplete="off" />
                        <label class="btn btn-outline-secondary  mb-3" for="option6">{reports[1]}</label>

                        <input type="radio" class="btn-check" name="options-base" onChange={handleChange} value={reports[2]} id="option7" autocomplete="off" />
                        <label class="btn btn-outline-secondary  mb-3" for="option7">{reports[2]}</label>

                        <input type="radio" class="btn-check" name="options-base" onChange={handleChange} value={reports[3]} id="option8" autocomplete="off" />
                        <label class="btn btn-outline-secondary  mb-3" for="option8"> {reports[3]}</label>

                        <input type="radio" class="btn-check" name="options-base" onChange={handleChange} value={reports[4]} id="option9" autocomplete="off" />
                        <label class="btn btn-outline-secondary  mb-3" for="option9"> {reports[4]}</label>

                        <input type="radio" class="btn-check" name="options-base" onChange={handleChange} value={reports[5]} id="option10" autocomplete="off" />
                        <label class="btn btn-outline-secondary  mb-3" for="option10"> {reports[5]}</label>

                        <button className="btn btn-primary col-12" type="button" onClick={submit}> Signalez</button>
                    </form>
                </div>
            </div>

        </div>
    );
}
export default Report;