import React, { useEffect, useState } from "react";
import Mapinput from "../../components/map/input";
import classes from "./voir_trajets_disponible.module.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import star from "../../assets/star.svg"
import half_star from "../../assets/half_star.svg"
import empty_star from "../../assets/empty_star.svg"
import Rating from 'react-rating';
import TransportFilter from "../../components/filters/ttrajets";
import fetchImage from "../../components/fetchImg";

const VoirTrajetsDispo = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { place1, place2 } = location.state || {}
    const [trajets, setTrajets] = useState([]);
    const [selectedPlace1, setSelectedPlace1] = useState(place1 || {});
    const [selectedPlace2, setSelectedPlace2] = useState(place2 || {});
    const [filter, setFilter] = useState({
        from_address: selectedPlace1,
        to_address: selectedPlace2,
        transportModes: [],
        transportRating: 0,
        timeOfDeparture: '',
        timeOfArrival: ''
    });
    const weightList = [
        "< 5KG",
        "Entre 5KG et 30KG ",
        "Entre 30KG et 50KG ",
        "Entre 50KG et 100KG ",
        ">100KG"
    ];
    const handlePlaceSelection1 = (place) => {
        setSelectedPlace1(place);
        setFilter({ ...filter, from_address: place });
    };

    const handlePlaceSelection2 = (place) => {
        setSelectedPlace2(place);
        setFilter({ ...filter, to_address: place });
    };
    const fetchData = async (filter = {}) => {
        try {
            console.log(filter)
            let url = `${process.env.REACT_APP_SERVER}/t_trajets/getByFilter`;

            const trajetResponse = await axios.get(url, { params: filter });
            const trajetData = trajetResponse.data;

            const promises = trajetData.map(async (TrajetItem) => {
                try {
                    const userResponse = await axios.get(`${process.env.REACT_APP_SERVER}/users/${TrajetItem.transporteur}`);
                    TrajetItem.transporteur = userResponse.data ?? {};
                    return TrajetItem;
                } catch (error) {
                    console.error("Error fetching transporteur data:", error);
                    return null;
                }
            });

            const updatedTrajetsData = await Promise.all(promises);
            const filteredTrajetsData = updatedTrajetsData.filter(item => item !== null);
            setTrajets(filteredTrajetsData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {


        fetchData(filter);
    }, []);

    const handleFilterChange = (newFilter) => {
        console.log(newFilter);
        setFilter({ ...filter, ...newFilter });
    };
    const handleTrajetClick = (trajet) => {
        console.log(trajet)
        navigate('/ajouterColis', { state: { trajet: trajet } });
    }
    return (
        <div className="container">
            <div className='row my-4'>
                <div className={`col-6 col-md-3`}>
                    <label htmlFor="">Ville de départ</label>
                    <Mapinput onChange={handlePlaceSelection1} placeholder={"Ville de départ"} />

                </div>
                <div className={`col-6 col-md-3`}>
                    <label htmlFor="">Ville d'arrivée</label>
                    <Mapinput onChange={handlePlaceSelection2} placeholder={"Ville d'arrivée"} />
                </div>
                <div className='col-6 col-md-2'>

                    <label htmlFor="" className='text-nowrap'>Date de départ</label>
                    <input className='form-control' type="date" name='date'
                        onChange={(e) => {
                            setFilter({ ...filter, go_time: e.target.value });
                        }}
                    />

                </div>
                <div className='col-6 col-md-2'>
                    <label htmlFor="" className='text-nowrap'>Date d'arrivé</label>
                    <input className='form-control' type="date" name='date'
                        onChange={(e) => {
                            setFilter({ ...filter, arrive_time: e.target.value });
                        }}
                    />
                </div>
                <div className="col-4 d-md-none mt-4 justify-content-bottom align-self-end">
                    <button className={`btn  ${classes.filter_button} col-12 btn-outline-secondary `} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">filtrer</button>
                </div>
                <div className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">Filtrer les trajets</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <TransportFilter onFilterChange={handleFilterChange} handleFilter={fetchData} filters={filter} />
                    </div>
                </div>
                <div className='col-8 col-md-2 mt-4 justify-content-bottom align-self-end'>
                    <button className={`btn  ${classes.form_button} col-12 `} value={"submit2"} onClick={() => { fetchData(filter) }}>Chercher</button>
                </div>
            </div>
            <div className="row min-vh-100 justify-content-between">
                <div className="col-md-4 bg-white shadow-sm d-none d-md-block">
                    <TransportFilter onFilterChange={handleFilterChange} handleFilter={fetchData} filters={filter} />
                </div>
                <div className="col-12 col-md-8 ">
                    {trajets.map((trajet) => (
                        <div className={`${classes.card} card mb-3 shadow border border-0 overflow-auto  w-100`} style={{ maxWidth: "550PX" }} onClick={() => { handleTrajetClick(trajet) }}>
                            <div className="row g-0">
                                <div className=" col-3 d-flex align-items-center p-2 position-relative">
                                    <img src={fetchImage(trajet.transporteur.profilePic)} className="img-fluid position-absolute top-50 start-50 translate-middle rounded-circle" style={{ objectFit: "cover", width: "100px", height: "100px" }} alt="..." />
                                </div>
                                <div className='col'>
                                    <div className="p-2">
                                        <div className='d-flex justify-content-between'>
                                            <p className="card-title text-capitalize fw-bold" style={{ color: "#1f406d", fontSize: "22px" }}>{trajet.transporteur.prenom}</p>
                                            <Rating
                                                readonly
                                                initialRating={trajet.transporteur.rate}
                                                fractions={2}  // Allows half-star increments
                                                emptySymbol={
                                                    <img src={empty_star} className="icon" alt="empty star" />
                                                }
                                                fullSymbol={
                                                    <img src={star} className="icon" alt="full star" />
                                                }
                                                halfSymbol={
                                                    <img src={half_star} className="icon" alt="half star" />
                                                }
                                            />

                                        </div>
                                        <div className="d-flex">
                                            <div className='col-8 d-flex'>
                                                <div className='d-flex flex-column justify-content-center text-center' style={{ width: "20px" }}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.0004 11.8084C8.22539 11.8084 6.77539 10.3667 6.77539 8.58337C6.77539 6.80003 8.22539 5.3667 10.0004 5.3667C11.7754 5.3667 13.2254 6.80837 13.2254 8.5917C13.2254 10.375 11.7754 11.8084 10.0004 11.8084ZM10.0004 6.6167C8.91706 6.6167 8.02539 7.50003 8.02539 8.5917C8.02539 9.68337 8.90872 10.5667 10.0004 10.5667C11.0921 10.5667 11.9754 9.68337 11.9754 8.5917C11.9754 7.50003 11.0837 6.6167 10.0004 6.6167Z" fill="black" fillOpacity="0.4" />
                                                        <path d="M10.0004 18.9666C8.76706 18.9666 7.52539 18.5 6.55872 17.575C4.10039 15.2083 1.38372 11.4333 2.40872 6.94163C3.33372 2.86663 6.89206 1.04163 10.0004 1.04163C10.0004 1.04163 10.0004 1.04163 10.0087 1.04163C13.1171 1.04163 16.6754 2.86663 17.6004 6.94996C18.6171 11.4416 15.9004 15.2083 13.4421 17.575C12.4754 18.5 11.2337 18.9666 10.0004 18.9666ZM10.0004 2.29163C7.57539 2.29163 4.45872 3.58329 3.63372 7.21663C2.73372 11.1416 5.20039 14.525 7.43372 16.6666C8.87539 18.0583 11.1337 18.0583 12.5754 16.6666C14.8004 14.525 17.2671 11.1416 16.3837 7.21663C15.5504 3.58329 12.4254 2.29163 10.0004 2.29163Z" fill="black" fillOpacity="0.4" />
                                                    </svg>
                                                    <svg className='align-self-center' width="2" height="50" viewBox="0 0 2 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M1 0C1.55228 0 2 0.621827 2 1.38889V48.6111C2 49.3782 1.55228 50 1 50C0.447715 50 0 49.3782 0 48.6111V1.38889C0 0.621827 0.447715 0 1 0Z" fill="black" fillOpacity="0.4" />
                                                    </svg>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.0004 11.8084C8.22539 11.8084 6.77539 10.3667 6.77539 8.58337C6.77539 6.80003 8.22539 5.3667 10.0004 5.3667C11.7754 5.3667 13.2254 6.80837 13.2254 8.5917C13.2254 10.375 11.7754 11.8084 10.0004 11.8084ZM10.0004 6.6167C8.91706 6.6167 8.02539 7.50003 8.02539 8.5917C8.02539 9.68337 8.90872 10.5667 10.0004 10.5667C11.0921 10.5667 11.9754 9.68337 11.9754 8.5917C11.9754 7.50003 11.0837 6.6167 10.0004 6.6167Z" fill="black" fillOpacity="0.4" />
                                                        <path d="M10.0004 18.9666C8.76706 18.9666 7.52539 18.5 6.55872 17.575C4.10039 15.2083 1.38372 11.4333 2.40872 6.94163C3.33372 2.86663 6.89206 1.04163 10.0004 1.04163C10.0004 1.04163 10.0004 1.04163 10.0087 1.04163C13.1171 1.04163 16.6754 2.86663 17.6004 6.94996C18.6171 11.4416 15.9004 15.2083 13.4421 17.575C12.4754 18.5 11.2337 18.9666 10.0004 18.9666ZM10.0004 2.29163C7.57539 2.29163 4.45872 3.58329 3.63372 7.21663C2.73372 11.1416 5.20039 14.525 7.43372 16.6666C8.87539 18.0583 11.1337 18.0583 12.5754 16.6666C14.8004 14.525 17.2671 11.1416 16.3837 7.21663C15.5504 3.58329 12.4254 2.29163 10.0004 2.29163Z" fill="black" fillOpacity="0.4" />
                                                    </svg>
                                                </div>
                                                <div className=' d-flex flex-column align-items-start text-start' style={{ height: "90px" }}>
                                                    <p className='m-0 fw-medium'>{trajet.from_address.name} le {new Date(trajet.go_time).toLocaleDateString('fr-FR', {
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                    })}</p>
                                                    <p className='m-0 fw-medium mt-auto'>{trajet.to_address.name} le {new Date(trajet.arrive_time).toLocaleDateString('fr-FR', {
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                    })}</p>

                                                </div>
                                            </div>
                                            <div className="col h-100 align-self-end">
                                                <div className='mt-auto border-start border-2 border-secondary-subtle ps-1'>
                                                    <p style={{ fontSize: "11px", fontWeight: "bold", margin: "0" }}>Manière : {trajet.maniere} </p>
                                                    <p style={{ fontSize: "11px", fontWeight: "bold", margin: "0" }}>Dim : {trajet.max_length} x {trajet.max_width} x {trajet.max_height} cm</p>
                                                    <p style={{ fontSize: "11px", fontWeight: "bold", margin: "0" }}>Poids: {weightList[trajet.max_weight]}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )

                    )}
                </div>
            </div>


        </div >
    );
};

export default VoirTrajetsDispo;
