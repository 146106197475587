import React, { useState, useEffect } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import PaginationComponent from "../../../components/paginations/pagination";
import ColisCollapse from "../../../components/colisCollapse/colisCollapse";

function Reports() {
    const [reports, setReports] = useState([]);
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState()
    const [rapportId, setReportId] = useState()

    const columns = [
        ["date de réclamation", "date_creation"],
        ["nom de réclamateur", "nom de réclamateur"],
        ["contenu", "content"],
        ["adresse de départ", "address"],
        ["adresse d'arrivé", "address"],
        ["date de départ", "address"],
        ["date d'arrivé", "address"],
        ["prix de particulier", "prix"],
        ["prix de transporteur", "prix"],
        ["opération", "operations"]
    ];
    const fetchCount = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/reports/count`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchData = async (page) => {
        try {
            console.log("fetching data")
            const trajetResponse = await axios.get(`${process.env.REACT_APP_SERVER}/reports?page=${page}&type=Trajet`);
            const trajetData = trajetResponse.data;
            console.log(trajetData)
            const filteredreportsData = trajetData.filter(item => item !== null);
            console.log(filteredreportsData)
            setReports(filteredreportsData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        fetchCount();
        fetchData(page);
    }, []);
    const supprmierTrajet = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_SERVER}/reports/deleteTrajet/${rapportId}`).then((res) => {
                console.log(res)
                fetchData()
            })
        } catch (error) {
            console.log(error)

        }
    }
    const supprimerReclamation = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_SERVER}/reports/${rapportId}`).then((res) => {
                console.log(res)
                fetchData()
            })
        } catch (error) {
            console.log(error)

        }
    }
    return (
        <Row style={{ maxWidth: "100%" }} >
            <Card className="Card" >
                <CardHeader className="CardHeader">
                    <CardTitle tag="h4">Liste des reports</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                    <div className="accordion" id="accordionExample">
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                <tr>
                                    {columns.map(column => <th key={column[1]}>{column[0]}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {reports.map((row, index) => (
                                    <React.Fragment key={index}>
                                        {row.subjectType === "Trajet" && <tr data-bs-toggle="collapse" data-bs-target={`#accordionContent${index}`} aria-expanded="false" aria-controls={`accordionContent${index}`}>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}> {new Date(row.date_creation).toLocaleDateString()}</td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                {row.user.nom + " " + row.user.prenom}
                                            </td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                {row.content}
                                            </td>

                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                {row.subject.from_address.name}
                                            </td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                {row.subject.to_address.name}

                                            </td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                {new Date(row.subject.go_time).toLocaleDateString()}
                                            </td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                {new Date(row.subject.arrive_time).toLocaleDateString()}

                                            </td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                {row.subject.prix}
                                            </td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                -
                                            </td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                <button type="button" className="btn btn-outline-danger btn-sm col-12  mb-2 " data-bs-toggle="modal" data-bs-target="#modalId5" onClick={() => setReportId(row._id)}>
                                                    Supprimer
                                                </button>
                                                <button type="button" className="btn btn-outline-secondary btn-sm col-12   " data-bs-toggle="modal" data-bs-target="#modalId6" onClick={() => setReportId(row._id)}>
                                                    Ingnorer
                                                </button>
                                            </td>
                                        </tr>}
                                        {row.subjectType === "TrajetOffre" && <tr data-bs-toggle="collapse" data-bs-target={`#accordionContent${index}`} aria-expanded="false" aria-controls={`accordionContent${index}`}>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}> {new Date(row.date_creation).toLocaleDateString()}</td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                {row.user.nom + " " + row.user.prenom}
                                            </td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                {row.content}
                                            </td>

                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                {row.subject.ttrajet.from_address.name}
                                            </td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                {row.subject.ttrajet.to_address.name}

                                            </td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                {new Date(row.subject.ttrajet.go_time).toLocaleDateString()}
                                            </td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                {new Date(row.subject.ttrajet.arrive_time).toLocaleDateString()}

                                            </td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                {row.subject.offre.prix}
                                            </td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                {row.subject.counterOffre.prix}
                                            </td>
                                            <td className="border-bottom-0 pb-0" style={{ verticalAlign: "middle" }}>
                                                <button type="button" className="btn btn-outline-danger btn-sm col-12  mb-2 " data-bs-toggle="modal" data-bs-target="#modalId5" onClick={() => setReportId(row._id)}>
                                                    Supprimer
                                                </button>
                                                <button type="button" className="btn btn-outline-secondary btn-sm col-12   " data-bs-toggle="modal" data-bs-target="#modalId6" onClick={() => setReportId(row._id)}>
                                                    Ingnorer
                                                </button>
                                            </td>
                                        </tr>}
                                        <ColisCollapse colis={row.subject.colis} colspan={columns.length} />
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <PaginationComponent page={page} lastPage={lastPage} setPage={setPage} fetchData={fetchData} />

                </CardBody>
            </Card>
            <div
                class="modal fade"
                id="modalId5"
                tabindex="-1"
                data-bs-backdrop="static"
                data-bs-keyboard="false"

                role="dialog"
                aria-labelledby="modalTitleId"
                aria-hidden="true"
            >
                <div
                    class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalTitleId">
                                Supprimer trajet
                            </h5>
                            <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div class="modal-body">

                            <div
                                class="alert alert-danger"
                                role="alert"
                            >
                                Vous êtes sur le point de supprimer ce trajet
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-outline-secondary w-auto"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" class="btn btn-danger w-auto" data-bs-dismiss="modal" onClick={supprmierTrajet}>Supprmier</button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="modal fade"
                id="modalId6"
                tabindex="-1"
                data-bs-backdrop="static"
                data-bs-keyboard="false"

                role="dialog"
                aria-labelledby="modalTitleId"
                aria-hidden="true"
            >
                <div
                    class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalTitleId">
                                Supprimer réclamaion
                            </h5>
                            <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div class="modal-body">

                            <div
                                class="alert alert-danger"
                                role="alert"
                            >
                                Vous êtes sur le point de supprimer cet réclamaion
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-outline-secondary w-auto"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" class="btn btn-danger w-auto" data-bs-dismiss="modal" onClick={supprimerReclamation}>Supprmier</button>
                        </div>
                    </div>
                </div>
            </div>
        </Row>
    );
}

export default Reports;
