import React, { useState, useEffect, useRef } from "react";
import { Row } from "reactstrap";
import axios from "axios";
import { Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import { RelaiMapSelection } from "../../../components/map/map";
import { useNavigate } from "react-router-dom"
import PaginationComponent from "../../../components/paginations/pagination";
import ColisCollapse from "../../../components/colisCollapse/colisCollapse";
import style from "../style.module.css"
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";

function OffresRecus({ openChat }) {
    const modalRef = useRef(null)
    const modalRef2 = useRef(null)
    const modalRef3 = useRef(null)
    const modalRef4 = useRef(null)
    const modalRef5 = useRef(null)
    const openModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = new Modal(modalElement); // Use window.bootstrap
        modalInstance.show();
    };
    const closeModal = (ref) => {
        const modalElement = ref.current;
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance.hide();
    }
    console.log('rendering offre recus')
    const navigate = useNavigate();
    const [selectedTrajet, setSelectedTrajet] = useState(null);
    const columns1 = [["date de départ", "go_time"], ["addresse de départ", "from_address.name"], ["date d'arrivé", "arrive_time"], ["addresse d'arrivé", "to_address.name"], ["prix publié", "trajet.prix"], ["prix offert", "offre.prix"], ["etat", "offre.etat"], ["Operations", "operations"]];
    const columns2 = [["date de départ", "go_time"], ["addresse de départ", "from_address.name"], ["date d'arrivé", "arrive_time"], ["addresse d'arrivé", "to_address.name"], ["prix offert", "offre.prix"], ["prix envoyé", "counteroffre.prix"], ["etat", "offre.etat"], ["Operations", "operations"]];
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [relai1, setRelai1] = useState(false)
    const [relai2, setRelai2] = useState(false)
    const [offreRelai1, setOffreRelai1] = useState()
    const [offreRelai2, setOffreRelai2] = useState()
    const [selectedOffre, setSelectedOffre] = useState(null)
    const [page1, setPage1] = useState(1)
    const [lastPage1, setLastPage1] = useState()
    const [page2, setPage2] = useState(1)
    const [lastPage2, setLastPage2] = useState()
    const fetchCount1 = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/offres/userCount`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage1(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchCount2 = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/TtrajetOffres/receivedCount`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage2(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchData1 = async (page) => {
        try {
            console.log("fetching offers");
            await axios.get(`${process.env.REACT_APP_SERVER}/offres?page=${page}`).then(async response => {
                console.log("offers received");
                const data = await Promise.all(response.data.map(async offre => {
                    // Format creation date
                    offre.date_creation = new Date(offre.date_creation).toLocaleDateString('fr-FR', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    });
                    const trajetRes = await axios.get(`${process.env.REACT_APP_SERVER}/trajets/trajet?trajet=${offre.trajet}`);
                    const colisRes = await axios.get(`${process.env.REACT_APP_SERVER}/colis/trajet?trajet=${offre.trajet}`);

                    let trajet = trajetRes.data;
                    trajet.offre = offre;
                    trajet.colis = colisRes.data;

                    return trajet;
                }));
                console.log(data);
                setData1(data);  // Update state with latest data
            }).catch((err) => {
                console.log(err)
                setData1([])
            });
        } catch (error) {
            setData1([])
            console.error("Error fetching offers:", error.message);
        }
    };
    const fetchData2 = async (page) => {
        try {
            console.log("fetching offers");
            await axios.get(`${process.env.REACT_APP_SERVER}/TtrajetOffres/recieved?page=${page}`).then(async (response) => {
                console.log("offers received");
                const data = await Promise.all(response.data.map(async offre => {
                    offre.date_creation = new Date(offre.date_creation).toLocaleDateString('fr-FR', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    });
                    const colis = await Promise.all(offre.colis.map(async coli => {
                        const res1 = await axios.get(`${process.env.REACT_APP_SERVER}/colis/${coli}`)
                        return res1.data
                    }))
                    const res2 = await axios.get(`${process.env.REACT_APP_SERVER}/t_trajets/${offre.ttrajet}`)
                    let trajet = res2.data
                    trajet.colis = colis
                    trajet.offre = offre
                    console.log(trajet)
                    return trajet;
                }));
                console.log(data);
                setData2(data);
            }).catch((err) => {
                console.log(err)
                setData2([])
            });

        } catch (error) {
            console.error("Error fetching offers:", error.message);
            return [];
        }
    };
    const refuserTtrajetOffre = async (index) => {///cbn
        try {
            const id = data2[index].offre._id;
            await axios.put(`${process.env.REACT_APP_SERVER}/TtrajetOffres/refuser/${id}`);
            fetchData2(page2);
        }
        catch (error) {
            console.error("Error accepting offre:", error);
        }
    }
    const refuserOffre = async (index) => {//cbn
        try {
            const id = data1[index].offre._id;
            await axios.put(`${process.env.REACT_APP_SERVER}/offres/refuser/${id}`).then(res => {
                console.log(res)
                fetchData1(page1);
            });
        }
        catch (error) {
            console.error("Error accepting offre:", error);
        }
    }
    const accepterOffre = async (index) => {//cbn
        try {
            const id = data1[index].offre._id;
            await axios.put(`${process.env.REACT_APP_SERVER}/offres/accepter/${id}`);
            await fetchData1(page1);
        }
        catch (error) {
            console.error("Error accepting offre:", error);
        }
    }
    const accepterTtrajetOffre = async () => {///cbn
        try {
            const id = selectedOffre._id;
            await axios.put(`${process.env.REACT_APP_SERVER}/TtrajetOffres/accepter/${id}`);
            fetchData2(page2);

        }
        catch (error) {
            console.error("Error accepting offre:", error);
        }
    }
    useEffect(() => {
        fetchCount1();
        fetchCount2();
        fetchData1(page1)
        fetchData2(page2)
    }, []);
    const submit = async () => {
        try {
            const id = selectedOffre._id
            console.log(`Updating offre ${id}`);
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/TtrajetOffres/${id}`, selectedOffre.counterOffre);
            console.log("Offre updated");
            console.log(response.data);
            fetchData2(page2);
        } catch (error) {
            console.error("Error updating offre:", error);
        }
    }
    const handleRelaiSelection1 = (place) => {
        console.log("Setting relai1");
        let offreSelection = structuredClone(selectedOffre);
        offreSelection.counterOffre.relai1 = place
        offreSelection.counterOffre.mam1 = false
        setSelectedOffre(offreSelection)
    };
    const handleRelaiSelection2 = (place) => {
        console.log("Setting relai2");
        let offreSelection = structuredClone(selectedOffre);
        offreSelection.counterOffre.relai2 = place
        offreSelection.counterOffre.mam2 = false
        setSelectedOffre(offreSelection)
    };
    const getRelai = async (id) => {
        try {
            let address
            await axios.get(`${process.env.REACT_APP_SERVER}/relais/${id}`).then((res) => {
                address = res.data
            }).catch((err) => {
                console.log(err)
            });
            return address
        } catch (err) {
            console.log(err)
            return null
        }
    }
    const handleOffreSelection = async (row) => {
        try {
            setSelectedTrajet(row)
            let offreSelection = row.offre
            if (offreSelection.offre.relai1) {
                const n1 = await getRelai(offreSelection.offre.relai1)
                setOffreRelai1(n1.address)
            }
            if (offreSelection.offre.relai2) {
                const n2 = await getRelai(offreSelection.offre.relai2)
                setOffreRelai2(n2.address)
            }
            if (offreSelection.counterOffre.relai1) {
                const n3 = await getRelai(offreSelection.counterOffre.relai1)
                offreSelection.counterOffre.relai1 = n3
            }
            if (offreSelection.counterOffre.relai2) {
                const n4 = await getRelai(offreSelection.counterOffre.relai2)
                offreSelection.counterOffre.relai2 = n4
            }

            if (offreSelection && offreSelection.counterOffre && offreSelection.counterOffre.relai2) {
                setRelai2(true)
            }
            else {
                setRelai2(false)
            }
            if (offreSelection && offreSelection.counterOffre && offreSelection.counterOffre.relai1) {
                setRelai1(true)
            }
            else {
                setRelai1(false)
            }
            console.log("offreSelection: ", offreSelection)
            setSelectedOffre(offreSelection)
        } catch (error) {

        }
    }
    const handleDelete1 = async (index) => {//cbn
        try {
            const id = data1[index].offre._id;
            await axios.delete(`${process.env.REACT_APP_SERVER}/offres/${id}`).then((res) => {
                fetchData1(page1);

            });
        }
        catch (error) {
            console.error("Error accepting offre:", error);
        }
    }
    const handleDelete2 = async (index) => {//cbn
        try {
            const id = data1[index].offre._id;
            await axios.delete(`${process.env.REACT_APP_SERVER}/TtrajetOffres/tansporteur/${id}`).then((res) => {
                fetchData2(page2);

            });
        }
        catch (error) {
            console.error("Error accepting offre:", error);
        }
    }
    const [code, setCode] = useState()

    const showCode = async (id) => {
        try {
            await axios.get(`${process.env.REACT_APP_SERVER}/receiveCode/${id}`).then((res) => {
                setCode(res.data.code)
                openModal(modalRef5)
            })
        } catch (error) {

        }
    }
    return (
        <Row style={{ maxWidth: "100%" }}>
            <nav>
                <div className={`${style.navs} nav nav-tabs`} id="nav-tab" role="tablist">
                    <button className="nav-link w-50 active" id="nav-colis-tab" data-bs-toggle="tab" data-bs-target="#nav-colis" type="button" role="tab" aria-controls="nav-colis" aria-selected="true"> <h5 className="text-start fw-lighter m-0">Mes colis</h5> </button>
                    <button className="nav-link w-50" id="nav-trajet-tab" data-bs-toggle="tab" data-bs-target="#nav-trajet" type="button" role="tab" aria-controls="nav-trajet" aria-selected="false"><h5 className="text-start fw-lighter m-0">Mes trajet</h5> </button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-colis" role="tabpanel" aria-labelledby="nav-colis-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">Offres sur mes colis</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns1.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion1">
                                    {data1.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr  >
                                                {columns1.map(column => (
                                                    <td key={column[1]} className=" align-middle border-bottom-0 pb-0">
                                                        {column[1] === "operations" ? (
                                                            <>
                                                                {row.offre.etat === "demandé" ? (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                            <button className="btn btn-outline-success btn-sm my-1 col-12" onClick={() => { accepterOffre(index) }} >
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M10.5799 15.5796C10.3799 15.5796 10.1899 15.4996 10.0499 15.3596L7.21994 12.5296C6.92994 12.2396 6.92994 11.7596 7.21994 11.4696C7.50994 11.1796 7.98994 11.1796 8.27994 11.4696L10.5799 13.7696L15.7199 8.62961C16.0099 8.33961 16.4899 8.33961 16.7799 8.62961C17.0699 8.91961 17.0699 9.39961 16.7799 9.68961L11.1099 15.3596C10.9699 15.4996 10.7799 15.5796 10.5799 15.5796Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>

                                                                            </button>
                                                                            <button className="btn btn-outline-danger btn-sm my-1 col-12" onClick={() => { refuserOffre(index) }}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M9.16999 15.5804C8.97999 15.5804 8.78999 15.5104 8.63999 15.3604C8.34999 15.0704 8.34999 14.5904 8.63999 14.3004L14.3 8.64035C14.59 8.35035 15.07 8.35035 15.36 8.64035C15.65 8.93035 15.65 9.41035 15.36 9.70035L9.69998 15.3604C9.55998 15.5104 9.35999 15.5804 9.16999 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M14.83 15.5804C14.64 15.5804 14.45 15.5104 14.3 15.3604L8.63999 9.70035C8.34999 9.41035 8.34999 8.93035 8.63999 8.64035C8.92999 8.35035 9.40998 8.35035 9.69998 8.64035L15.36 14.3004C15.65 14.5904 15.65 15.0704 15.36 15.3604C15.21 15.5104 15.02 15.5804 14.83 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>

                                                                            </button>

                                                                        </div>
                                                                        {/*<div >
                                                                            <button className="btn btn-outline-success btn-sm my-1 col-12" onClick={() => { accepterOffre(index) }} >Accepter</button>
                                                                            <button className="btn btn-outline-danger btn-sm my-1 col-12" onClick={() => { refuserOffre(index) }}>Refuser</button>
                                                                        </div>*/}

                                                                    </>
                                                                ) : row.offre.etat === "refusé" ? (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>

                                                                            <button className="btn btn-outline-danger  btn-sm my-1 col-12" onClick={() => { handleDelete1(index) }}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M9.16999 15.5804C8.97999 15.5804 8.78999 15.5104 8.63999 15.3604C8.34999 15.0704 8.34999 14.5904 8.63999 14.3004L14.3 8.64035C14.59 8.35035 15.07 8.35035 15.36 8.64035C15.65 8.93035 15.65 9.41035 15.36 9.70035L9.69998 15.3604C9.55998 15.5104 9.35999 15.5804 9.16999 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M14.83 15.5804C14.64 15.5804 14.45 15.5104 14.3 15.3604L8.63999 9.70035C8.34999 9.41035 8.34999 8.93035 8.63999 8.64035C8.92999 8.35035 9.40998 8.35035 9.69998 8.64035L15.36 14.3004C15.65 14.5904 15.65 15.0704 15.36 15.3604C15.21 15.5104 15.02 15.5804 14.83 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                        {/*                                                                        <button className="btn btn-outline-danger  btn-sm my-1 col-12" onClick={() => { handleDelete1(index) }}>Supprimer</button>*/}                                                                    </>
                                                                ) : row.offre.etat === "accepté" ? (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>

                                                                            <button className="btn btn-outline-danger  btn-sm my-1 col-12" onClick={() => { navigate(`/payement`, { state: row }) }}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M13.05 16.25H11.17C9.84001 16.25 8.75 15.13 8.75 13.75C8.75 13.34 9.09 13 9.5 13C9.91 13 10.25 13.34 10.25 13.75C10.25 14.3 10.66 14.75 11.17 14.75H13.05C13.44 14.75 13.75 14.4 13.75 13.97C13.75 13.43 13.6 13.35 13.26 13.23L10.25 12.18C9.61 11.95 8.75 11.49 8.75 10.02C8.75 8.76999 9.74001 7.73999 10.95 7.73999H12.83C14.16 7.73999 15.25 8.85999 15.25 10.24C15.25 10.65 14.91 10.99 14.5 10.99C14.09 10.99 13.75 10.65 13.75 10.24C13.75 9.68999 13.34 9.23999 12.83 9.23999H10.95C10.56 9.23999 10.25 9.58999 10.25 10.02C10.25 10.56 10.4 10.64 10.74 10.76L13.75 11.81C14.39 12.04 15.25 12.5 15.25 13.97C15.25 15.23 14.26 16.25 13.05 16.25Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M12 17.25C11.59 17.25 11.25 16.91 11.25 16.5V7.5C11.25 7.09 11.59 6.75 12 6.75C12.41 6.75 12.75 7.09 12.75 7.5V16.5C12.75 16.91 12.41 17.25 12 17.25Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2C12.75 2.41 12.41 2.75 12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 11.59 21.59 11.25 22 11.25C22.41 11.25 22.75 11.59 22.75 12C22.75 17.93 17.93 22.75 12 22.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M22 6.75C21.59 6.75 21.25 6.41 21.25 6V2.75H18C17.59 2.75 17.25 2.41 17.25 2C17.25 1.59 17.59 1.25 18 1.25H22C22.41 1.25 22.75 1.59 22.75 2V6C22.75 6.41 22.41 6.75 22 6.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M16.9999 7.74994C16.8099 7.74994 16.6199 7.67994 16.4699 7.52994C16.1799 7.23994 16.1799 6.75994 16.4699 6.46994L21.4699 1.46994C21.7599 1.17994 22.2399 1.17994 22.5299 1.46994C22.8199 1.75994 22.8199 2.23994 22.5299 2.52994L17.5299 7.52994C17.3799 7.67994 17.1899 7.74994 16.9999 7.74994Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                        {/*<button className="btn btn-outline-success  btn-sm my-1 col-12" onClick={() => { navigate(`/payement`, { state: row }) }}>Payer</button>*/}
                                                                    </>
                                                                ) : row.offre.etat === "payé" ? (
                                                                    <button className="btn btn-outline-primary btn-sm my-1 col-12" onClick={() => openChat(row.offre._id, "TrajetOffre")}>Discuter</button>
                                                                ) : row.offre.etat === "en cours" ? (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                            <button className="btn btn-outline-primary rounded-circle w-auto " onClick={() => { showCode(row.offre._id) }}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M18 10.75C17.59 10.75 17.25 10.41 17.25 10V8C17.25 4.85 16.36 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.75 10.41 6.41 10.75 6 10.75C5.59 10.75 5.25 10.41 5.25 10V8C5.25 5.1 5.95 1.25 12 1.25C18.05 1.25 18.75 5.1 18.75 8V10C18.75 10.41 18.41 10.75 18 10.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M12 19.25C10.21 19.25 8.75 17.79 8.75 16C8.75 14.21 10.21 12.75 12 12.75C13.79 12.75 15.25 14.21 15.25 16C15.25 17.79 13.79 19.25 12 19.25ZM12 14.25C11.04 14.25 10.25 15.04 10.25 16C10.25 16.96 11.04 17.75 12 17.75C12.96 17.75 13.75 16.96 13.75 16C13.75 15.04 12.96 14.25 12 14.25Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M17 22.75H7C2.59 22.75 1.25 21.41 1.25 17V15C1.25 10.59 2.59 9.25 7 9.25H17C21.41 9.25 22.75 10.59 22.75 15V17C22.75 21.41 21.41 22.75 17 22.75ZM7 10.75C3.42 10.75 2.75 11.43 2.75 15V17C2.75 20.57 3.42 21.25 7 21.25H17C20.58 21.25 21.25 20.57 21.25 17V15C21.25 11.43 20.58 10.75 17 10.75H7Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>

                                                                            </button>
                                                                            <button className="btn btn-outline-primary rounded-circle w-auto " onClick={() => navigate('/suivi', { state: { type: "trajet", id: row.offre._id } })}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M12 14.1699C9.87 14.1699 8.13 12.4399 8.13 10.2999C8.13 8.15994 9.87 6.43994 12 6.43994C14.13 6.43994 15.87 8.16994 15.87 10.3099C15.87 12.4499 14.13 14.1699 12 14.1699ZM12 7.93994C10.7 7.93994 9.63 8.99994 9.63 10.3099C9.63 11.6199 10.69 12.6799 12 12.6799C13.31 12.6799 14.37 11.6199 14.37 10.3099C14.37 8.99994 13.3 7.93994 12 7.93994Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M12 22.76C10.52 22.76 9.02999 22.2 7.86999 21.09C4.91999 18.25 1.65999 13.72 2.88999 8.33C3.99999 3.44 8.26999 1.25 12 1.25C12 1.25 12 1.25 12.01 1.25C15.74 1.25 20.01 3.44 21.12 8.34C22.34 13.73 19.08 18.25 16.13 21.09C14.97 22.2 13.48 22.76 12 22.76ZM12 2.75C9.08999 2.75 5.34999 4.3 4.35999 8.66C3.27999 13.37 6.23999 17.43 8.91999 20C10.65 21.67 13.36 21.67 15.09 20C17.76 17.43 20.72 13.37 19.66 8.66C18.66 4.3 14.91 2.75 12 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>
                                                                            <button className="btn btn-outline-success rounded-circle w-auto" >
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => openChat(row.offre._id, "TrajetOffre")}>
                                                                                    <path d="M15.5 11.25H8.5C8.09 11.25 7.75 10.91 7.75 10.5C7.75 10.09 8.09 9.75 8.5 9.75H15.5C15.91 9.75 16.25 10.09 16.25 10.5C16.25 10.91 15.91 11.25 15.5 11.25Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M16 22.3199C15.66 22.3199 15.32 22.22 15.03 22.03L10.77 19.1899H7C3.56 19.1899 1.25 16.8799 1.25 13.4399V7.43994C1.25 3.99994 3.56 1.68994 7 1.68994H17C20.44 1.68994 22.75 3.99994 22.75 7.43994V13.4399C22.75 16.6199 20.77 18.84 17.75 19.15V20.5699C17.75 21.2199 17.4 21.8099 16.83 22.1099C16.57 22.2499 16.28 22.3199 16 22.3199ZM7 3.17993C4.42 3.17993 2.75 4.84993 2.75 7.42993V13.4299C2.75 16.0099 4.42 17.6799 7 17.6799H11C11.15 17.6799 11.29 17.7199 11.42 17.8099L15.87 20.77C15.98 20.84 16.08 20.81 16.13 20.78C16.18 20.75 16.26 20.6899 16.26 20.5599V18.4299C16.26 18.0199 16.6 17.6799 17.01 17.6799C19.59 17.6799 21.26 16.0099 21.26 13.4299V7.42993C21.26 4.84993 19.59 3.17993 17.01 3.17993H7Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>

                                                                        {/*<button className="btn btn-outline-primary btn-sm my-1 col-12" onClick={() => openChat(row.offre._id, "TrajetOffre")}>Discuter</button>
                                                                        <button className="btn btn-outline-success btn-sm my-1 col-12" onClick={() => {
                                                                            console.log(row)
                                                                            navigate('/suivi', { state: { type: "trajet", id: row._id } })
                                                                        }}
                                                                        >Suivi</button>*/}
                                                                    </>
                                                                ) : (
                                                                    <button className="btn btn-outline-secondary   btn-sm my-1 col-12" disabled>Aucune</button>
                                                                )}
                                                            </>

                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR') ? new Date(row.go_time).toLocaleDateString('fr-FR') : "Introuvable"
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR') ? new Date(row.arrive_time).toLocaleDateString('fr-FR') : "Introuvable"
                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : "Introuvable"
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : "Introuvable"
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : "Introuvable"
                                                        ) : column[1] === "trajet.prix" ? (
                                                            row.prix ? row.prix : "Introuvable"
                                                        ) : column[1] === "offre.prix" ? (
                                                            row.offre.prix ? row.offre.prix : "Introuvable"
                                                        ) : column[1] === "offre.etat" ? (
                                                            row.offre.etat ? row.offre.etat : "Introuvable"
                                                        ) : (
                                                            row[column[1]] || "Introuvable"
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <ColisCollapse colis={row.colis} colspan={columns1.length} />
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>

                        </CardBody>
                        <PaginationComponent page={page1} lastPage={lastPage1} setPage={setPage1} fetchData={fetchData1} />

                    </Card>
                </div >
                <div className="tab-pane fade" id="nav-trajet" role="tabpanel" aria-labelledby="nav-trajet-tab" tabIndex="0">
                    <Card className="Card overflow-auto" style={{ maxWidth: "100%" }} >
                        <CardHeader className="CardHeader">
                            <CardTitle tag="h4">Offres sur mes trajets</CardTitle>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        {columns2.map(column => <th key={column[1]}>{column[0]}</th>)}
                                    </tr>
                                </thead>
                                <tbody className="accordion" id="accordion2">
                                    {data2.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr >
                                                {columns2.map(column => (
                                                    <td key={column[1]} className=" align-middle border-bottom-0 pb-0">
                                                        {column[1] === "operations" ? (
                                                            <>
                                                                {row.offre.etat === "demandé" ? (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                            <button className="btn btn-outline-primary rounded-circle w-auto " onClick={() => {
                                                                                openModal(modalRef)
                                                                                handleOffreSelection(row)
                                                                            }}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M12 16.3299C9.61001 16.3299 7.67001 14.3899 7.67001 11.9999C7.67001 9.60992 9.61001 7.66992 12 7.66992C14.39 7.66992 16.33 9.60992 16.33 11.9999C16.33 14.3899 14.39 16.3299 12 16.3299ZM12 9.16992C10.44 9.16992 9.17001 10.4399 9.17001 11.9999C9.17001 13.5599 10.44 14.8299 12 14.8299C13.56 14.8299 14.83 13.5599 14.83 11.9999C14.83 10.4399 13.56 9.16992 12 9.16992Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M12 21.02C8.23999 21.02 4.68999 18.82 2.24999 15C1.18999 13.35 1.18999 10.66 2.24999 8.99998C4.69999 5.17998 8.24999 2.97998 12 2.97998C15.75 2.97998 19.3 5.17998 21.74 8.99998C22.8 10.65 22.8 13.34 21.74 15C19.3 18.82 15.75 21.02 12 21.02ZM12 4.47998C8.76999 4.47998 5.67999 6.41998 3.51999 9.80998C2.76999 10.98 2.76999 13.02 3.51999 14.19C5.67999 17.58 8.76999 19.52 12 19.52C15.23 19.52 18.32 17.58 20.48 14.19C21.23 13.02 21.23 10.98 20.48 9.80998C18.32 6.41998 15.23 4.47998 12 4.47998Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>

                                                                            </button>
                                                                            <button className="btn btn-outline-danger rounded-circle w-auto" onClick={() => refuserTtrajetOffre(index)}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M9.16999 15.5804C8.97999 15.5804 8.78999 15.5104 8.63999 15.3604C8.34999 15.0704 8.34999 14.5904 8.63999 14.3004L14.3 8.64035C14.59 8.35035 15.07 8.35035 15.36 8.64035C15.65 8.93035 15.65 9.41035 15.36 9.70035L9.69998 15.3604C9.55998 15.5104 9.35999 15.5804 9.16999 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M14.83 15.5804C14.64 15.5804 14.45 15.5104 14.3 15.3604L8.63999 9.70035C8.34999 9.41035 8.34999 8.93035 8.63999 8.64035C8.92999 8.35035 9.40998 8.35035 9.69998 8.64035L15.36 14.3004C15.65 14.5904 15.65 15.0704 15.36 15.3604C15.21 15.5104 15.02 15.5804 14.83 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>

                                                                            </button>
                                                                        </div>
                                                                        {/*<button className="btn btn-outline-primary btn-sm my-1 col-12" onClick={() => handleOffreSelection(row)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                            Voir</button>
                                                                        <button className="btn btn-outline-danger btn-sm my-1 col-12" onClick={() => refuserTtrajetOffre(index)}>Réfuser</button>*/}
                                                                    </>
                                                                ) : row.offre.etat === "accepté" ? (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                            <button className="btn btn-outline-primary rounded-circle w-auto " onClick={() => handleOffreSelection(row)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M12 16.3299C9.61001 16.3299 7.67001 14.3899 7.67001 11.9999C7.67001 9.60992 9.61001 7.66992 12 7.66992C14.39 7.66992 16.33 9.60992 16.33 11.9999C16.33 14.3899 14.39 16.3299 12 16.3299ZM12 9.16992C10.44 9.16992 9.17001 10.4399 9.17001 11.9999C9.17001 13.5599 10.44 14.8299 12 14.8299C13.56 14.8299 14.83 13.5599 14.83 11.9999C14.83 10.4399 13.56 9.16992 12 9.16992Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M12 21.02C8.23999 21.02 4.68999 18.82 2.24999 15C1.18999 13.35 1.18999 10.66 2.24999 8.99998C4.69999 5.17998 8.24999 2.97998 12 2.97998C15.75 2.97998 19.3 5.17998 21.74 8.99998C22.8 10.65 22.8 13.34 21.74 15C19.3 18.82 15.75 21.02 12 21.02ZM12 4.47998C8.76999 4.47998 5.67999 6.41998 3.51999 9.80998C2.76999 10.98 2.76999 13.02 3.51999 14.19C5.67999 17.58 8.76999 19.52 12 19.52C15.23 19.52 18.32 17.58 20.48 14.19C21.23 13.02 21.23 10.98 20.48 9.80998C18.32 6.41998 15.23 4.47998 12 4.47998Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>

                                                                            </button>
                                                                        </div>
                                                                        {/*<button className="btn btn-outline-primary btn-sm my-1 col-12" >Détails </button>*/}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className={`${style.roundedButtonsContainer} d-flex justify-content-around `}>
                                                                            <button className="btn btn-outline-danger rounded-circle w-auto " onClick={() => handleDelete2(index)}>
                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M9.16999 15.5804C8.97999 15.5804 8.78999 15.5104 8.63999 15.3604C8.34999 15.0704 8.34999 14.5904 8.63999 14.3004L14.3 8.64035C14.59 8.35035 15.07 8.35035 15.36 8.64035C15.65 8.93035 15.65 9.41035 15.36 9.70035L9.69998 15.3604C9.55998 15.5104 9.35999 15.5804 9.16999 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                                    <path d="M14.83 15.5804C14.64 15.5804 14.45 15.5104 14.3 15.3604L8.63999 9.70035C8.34999 9.41035 8.34999 8.93035 8.63999 8.64035C8.92999 8.35035 9.40998 8.35035 9.69998 8.64035L15.36 14.3004C15.65 14.5904 15.65 15.0704 15.36 15.3604C15.21 15.5104 15.02 15.5804 14.83 15.5804Z" fill="currentColor" fill-opacity="0.88" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                        {/*<button className="btn btn-outline-danger my-1 btn-sm col-12" onClick={() => handleDelete2(index)} >Supprimer</button>*/}
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : column[1] === "arrive_time" ? (
                                                            new Date(row.go_time).toLocaleDateString('fr-FR') ? new Date(row.go_time).toLocaleDateString('fr-FR') : "Introuvable"
                                                        ) : column[1] === "go_time" ? (
                                                            new Date(row.arrive_time).toLocaleDateString('fr-FR') ? new Date(row.arrive_time).toLocaleDateString('fr-FR') : "Introuvable"
                                                        ) : column[1] === "from_address.name" ? (
                                                            row.from_address.name ? row.from_address.name : "Introuvable"
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : "Introuvable"
                                                        ) : column[1] === "to_address.name" ? (
                                                            row.to_address.name ? row.to_address.name : "Introuvable"
                                                        ) : column[1] === "offre.prix" ? (
                                                            row.offre.offre.prix ? row.offre.offre.prix : "Introuvable"
                                                        ) : column[1] === "counteroffre.prix" ? (
                                                            row.offre?.counterOffre?.prix ? row.offre.counterOffre.prix : "Introuvable"
                                                        ) : column[1] === "offre.etat" ? (
                                                            row.offre.etat ? row.offre.etat : "Introuvable"
                                                        ) : (
                                                            row[column[1]] || "Introuvable"
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            <ColisCollapse colis={row.colis} colspan={columns2.length} />
                                        </React.Fragment>

                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                        <PaginationComponent page={page2} lastPage={lastPage2} setPage={setPage2} fetchData={fetchData2} />

                    </Card>
                </div>
                <div className="modal modal-xl fade " ref={modalRef} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" >
                        <div className="modal-content" style={{ backgroundColor: "#eaf0ff" }}>
                            <div className="modal-header" style={{ backgroundColor: "#2774f6" }}>
                                <h1 className="modal-title fs-5 text-light" id="exampleModalLabel">Consulter l'offre</h1>
                                <button type="button" className="btn-close" aria-label="Close" onClick={() => { closeModal(modalRef) }}></button>
                            </div>
                            <div className="modal-body" >
                                <div className="row">
                                    <div className="col ">
                                        <div className="card rounded-5 p-3 h-100" >


                                            <h5 className="card-title">Offre reçu</h5>
                                            <div className="card-body">
                                                <h6 className="fw-bold">DÉPART :</h6>
                                                <p>
                                                    Récupérer main à main : {selectedOffre && selectedOffre.offre.mam1 === true ? "Oui" : "Non"}
                                                </p>
                                                <p>
                                                    Récupérer en point relais : {(selectedOffre && selectedOffre.offre.relai1) ? (offreRelai1) : ("Non")}
                                                </p>
                                                <h6 className="fw-bold">ARRIVÉ :</h6>
                                                <p>
                                                    Déposer main à main : {selectedOffre && selectedOffre.offre.mam2 === true ? "Oui" : "Non"}
                                                </p>
                                                <p>
                                                    Déposer en point relais : {(selectedOffre && selectedOffre.offre.relai2) ? (offreRelai2) : ("Non")}
                                                </p>
                                                <div> <b>PRIX : </b> {(selectedOffre && selectedOffre.offre.prix) ? (selectedOffre.offre.prix) : ("Introuvable")}</div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card rounded-5 p-3 h-100" >
                                            <h5 className="card-title">Votre offre</h5>
                                            <div className="card-body">
                                                <fieldset>
                                                    <h6 className="fw-bold">DÉPART</h6>
                                                    <div className="d-flex">
                                                        <p className="me-4">Récupérer main à main :</p>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="mam1" // Separate name for this group
                                                                id="r1"
                                                                value="oui"
                                                                checked={selectedOffre && selectedOffre.counterOffre && selectedOffre.counterOffre.mam1}
                                                                onChange={(event) => {
                                                                    let offreSelection = selectedOffre
                                                                    offreSelection.counterOffre.mam1 = true;
                                                                    setSelectedOffre(offreSelection);

                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r1">Oui</label>
                                                        </div>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="mam1"
                                                                id="r2"
                                                                value="non"
                                                                checked={selectedOffre && selectedOffre.counterOffre && !selectedOffre.counterOffre.mam1}
                                                                onChange={(event) => {
                                                                    let offreSelection = selectedOffre
                                                                    offreSelection.counterOffre.mam1 = false;
                                                                    setSelectedOffre(offreSelection);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r2">Non</label>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex">
                                                        <p className="me-4">Récupérer en point relais :</p>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="relai1" // Separate name for this group
                                                                id="r3"
                                                                value="oui"
                                                                checked={relai1}

                                                                onChange={(event) => {
                                                                    setRelai1(true);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r3">Oui</label>
                                                        </div>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="relai1"
                                                                id="r4"
                                                                value="non"
                                                                checked={!relai1}

                                                                onChange={(event) => {
                                                                    setRelai1(false);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r4">Non</label>
                                                        </div>
                                                    </div>
                                                    {relai1 === true && (
                                                        <button
                                                            className="btn btn-outline-primary col-12 mb-4"
                                                            onClick={() => {
                                                                closeModal(modalRef)
                                                                openModal(modalRef2)
                                                            }} >
                                                            Selectionner une point relais
                                                        </button>
                                                    )}
                                                </fieldset>

                                                <fieldset>
                                                    <h6 className="fw-bold">ARRIVÉ</h6>

                                                    <div className="d-flex">
                                                        <p className="me-4">Déposer main à main :</p>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="mam2" // Separate name for this group
                                                                id="r5"
                                                                value="oui"
                                                                checked={selectedOffre && selectedOffre.counterOffre && selectedOffre.counterOffre.mam2}

                                                                onChange={(event) => {
                                                                    let offreSelection = selectedOffre
                                                                    offreSelection.counterOffre.mam2 = true;
                                                                    setSelectedOffre(offreSelection);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r5">Oui</label>
                                                        </div>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="mam2"
                                                                id="r6"
                                                                value="non"
                                                                checked={selectedOffre && selectedOffre.counterOffre && !selectedOffre.counterOffre.mam2}
                                                                onChange={(event) => {
                                                                    let offreSelection = selectedOffre
                                                                    offreSelection.counterOffre.mam2 = false;
                                                                    setSelectedOffre(offreSelection);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r6">Non</label>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex">
                                                        <p className="me-4">Déposer en point relais :</p>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="relai2" // Separate name for this group
                                                                id="r7"
                                                                value="oui"
                                                                checked={relai2}
                                                                onChange={(event) => {
                                                                    setRelai2(true);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r7">Oui</label>
                                                        </div>
                                                        <div className="form-check me-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="relai2"
                                                                id="r8"
                                                                value="non"
                                                                checked={!relai2}
                                                                onChange={(event) => {
                                                                    setRelai2(false);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="r8">Non</label>
                                                        </div>
                                                    </div>
                                                    {relai2 === true && (
                                                        <button className="btn btn-outline-primary col-12 mb-4"
                                                            onClick={() => {
                                                                closeModal(modalRef)
                                                                openModal(modalRef3)
                                                            }} >Selectionner une point relais</button>
                                                    )}
                                                </fieldset>
                                                <fieldset>
                                                    <h6 className="fw-bold">PRIX</h6>
                                                    <input className="form-control" type="number" placeholder="Saisir le prix" value={selectedOffre?.counterOffre?.prix ?? ''}
                                                        onChange={(event) => {
                                                            let offreSelection = structuredClone(selectedOffre);
                                                            offreSelection.counterOffre.prix = Number(event.target.value);
                                                            setSelectedOffre(offreSelection);
                                                        }} />
                                                </fieldset>
                                            </div>


                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-secondary"
                                    onClick={() => {
                                        closeModal(modalRef)
                                        setSelectedOffre(undefined)
                                    }}>Annuler</button>
                                <button type="button" className="btn btn-outline-success" data-bs-target="#modalToggle4" data-bs-toggle="modal"
                                    onClick={() => {
                                        closeModal(modalRef)
                                        openModal(modalRef4)
                                    }}>Accepter</button>
                                <button type="button" className="btn btn-outline-primary" onClick={() => {
                                    submit()
                                    closeModal(modalRef)
                                    setSelectedOffre(undefined)
                                }}>Envoyer</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade modal-xl" ref={modalRef2} aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Selectionner une point relais</h1>
                                <button type="button" className="btn-close"
                                    onClick={() => {
                                        closeModal(modalRef2)
                                        openModal(modalRef)
                                    }} aria-label="Close"></button>
                            </div>
                            <div className="modal-body" style={{ height: "70vh" }}>
                                <RelaiMapSelection
                                    setRelai={handleRelaiSelection1}
                                    currentModal={modalRef2}
                                    nextModalRef={modalRef}
                                    location={selectedOffre?.counterOffre?.relai1?.location ?? selectedTrajet?.from_address?.location}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-secondary"
                                    onClick={() => {
                                        closeModal(modalRef2)
                                        openModal(modalRef)
                                    }} >Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade modal-xl" ref={modalRef3} aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Selectionner une point relais</h1>
                                <button type="button" className="btn-close"
                                    onClick={() => {
                                        closeModal(modalRef3)
                                        openModal(modalRef)
                                    }} aria-label="Close"></button>
                            </div>
                            <div className="modal-body" style={{ height: "70vh" }}>
                                <RelaiMapSelection
                                    setRelai={handleRelaiSelection2}
                                    currentModal={modalRef3}
                                    nextModalRef={modalRef}
                                    location={selectedOffre?.counterOffre?.relai2?.location ?? selectedTrajet?.to_address?.location}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-secondary"
                                    onClick={() => {
                                        closeModal(modalRef3)
                                        openModal(modalRef)
                                    }} >Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade " ref={modalRef4} aria-hidden="true" aria-labelledby="modalToggleLabel2" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Accepter l'offre</h1>
                                <button type="button" className="btn-close" onClick={() => {
                                    closeModal(modalRef4)
                                    openModal(modalRef)

                                }} aria-label="Close"></button>
                            </div>
                            <div className="modal-body" >

                                <p className="fs-5 text-danger">
                                    Êtes-vous sûr de vouloir accepter cette offre avec ces détails ?
                                </p>
                                <h6 className="fw-bold">DÉPART :</h6>
                                <p>
                                    Récupérer main à main : {selectedOffre && selectedOffre.offre.mam1 === true ? "Oui" : "Non"}
                                </p>
                                <p>
                                    Récupérer en point relais : {(selectedOffre && selectedOffre.offre.relai1) ? (offreRelai1) : ("Non")}
                                </p>
                                <h6 className="fw-bold">ARRIVÉ :</h6>
                                <p>
                                    Déposer main à main : {selectedOffre && selectedOffre.offre.mam2 === true ? "Oui" : "Non"}
                                </p>
                                <p>
                                    Déposer en point relais : {(selectedOffre && selectedOffre.offre.relai2) ? (offreRelai2) : ("Non")}
                                </p>
                                <div> <b>PRIX : </b> {(selectedOffre && selectedOffre.offre.prix) ? (selectedOffre.offre.prix) : ("Introuvable")}</div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-secondary" onClick={() => {
                                    closeModal(modalRef4)
                                    openModal(modalRef)
                                }}>Annuler</button>
                                <button type="button" className="btn btn-outline-primary position-relative top-0 "
                                    onClick={() => {
                                        accepterTtrajetOffre()
                                        closeModal(modalRef4)
                                        openModal(modalRef)
                                    }}
                                >
                                    Accepter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div
                class="modal fade"
                ref={modalRef5}
                tabindex="-1"
                data-bs-backdrop="static"
                data-bs-keyboard="false"

                role="dialog"
                aria-labelledby="modalTitleId"
                aria-hidden="true"
            >
                <div
                    class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalTitleId">
                                Code de validation
                            </h5>
                            <button
                                type="button"
                                class="btn-close"
                                onClick={() => { closeModal(modalRef5) }}
                                aria-label="Close"
                            ></button>
                        </div>
                        <div class="modal-body">
                            <p>
                                Donnez ce code à le transporteur pour valider l'opération
                            </p>
                            <h1>
                                {code}
                            </h1>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-outline-secondary w-auto"
                                onClick={() => { closeModal(modalRef5) }}
                            >
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Row >
    );
}

export default OffresRecus;
