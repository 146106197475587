
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function ResetPssword() {
    const [container, setContainer] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const location = useLocation();
    const reset = async () => {
        try {
            const params = new URLSearchParams(location.search);
            const token = params.get('token');
            if (password !== password2) {
                alert('Les mots de passe ne correspondent pas')
                return
            }
            console.log(password)
            // Send a request to your server to verify the email
            await axios.post(`${process.env.REACT_APP_SERVER}/users/resetPassword/${token}`, { password: password }).then((res) => {
                setContainer(1)

            }).catch((err) => {
                setContainer(2)

            });

        } catch (error) {
            console.log(error);
        }
    };


    return (
        <div>
            {!container && (<>
                <div className='row mt-4'>
                    <div className=' col-12 col-md-4 mx-auto align-self-center'>
                        <h2 className='text-center mb-4'>Réinitialiser votre mot de passe</h2>
                        <div className='mb-4'>
                            <label for="" class="form-label">Nouveau mot de passe</label>
                            <input type="password" class="form-control" id="" required onChange={
                                (e) => {
                                    setPassword(e.target.value)
                                }
                            } />
                        </div>
                        <div >
                            <label for="" class="form-label">Répeter mot de passe</label>
                            <input type="password" class="form-control" id="" required onChange={
                                (e) => {
                                    setPassword2(e.target.value)
                                }
                            } />
                        </div>
                        <div >
                            <button class="btn btn-primary w-auto mt-4" onClick={reset}>Réinitialiser</button>
                        </div >
                    </div >

                </div >
            </>)
            }
            {
                container === 1 &&
                (<div className="container" >
                    <div class="col-10 col-md-5 card p-2 mt-4 mx-auto border-0 shadow">
                        <div className="row">
                            <div className="col-6 mx-auto my-4">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                    <circle class="path circle" fill="none" stroke="#198754" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                    <polyline class="path check" fill="none" stroke="#198754" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                </svg>
                            </div>
                        </div>

                        <div class="card-body text-center">
                            <h4 class="text-success mt-3">Réinitailsation réussie!</h4>
                            <p class="mt-3">
                                Votre mot de passe a été réinitialisé avec succès. Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.
                            </p>
                        </div>
                    </div>
                </div>)
            }
            {
                container === 2 && (
                    <div className="container" >
                        <div class="col-10 col-md-5 card p-2 mt-4 mx-auto border-0 shadow">
                            <div className="row">
                                <div className="col-6 mx-auto my-4">
                                    <svg version="1.1" className="card-img-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle class="path circle" fill="none" stroke="#db3646" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <line class="path line" fill="none" stroke="#db3646" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                        <line class="path line" fill="none" stroke="#db3646" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="37.9" x2="34.4" y2="92.3" />
                                    </svg>
                                </div>
                            </div>

                            <div class="card-body text-center">
                                <h4 class="text-danger mt-3">Réinitialisation échoué</h4>
                                <p class="mt-3">
                                    Votre lien de réinitialisation a expiré ou est invalide. Veuillez réessayer.
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    );
}

export default ResetPssword;