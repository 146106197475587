import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import axios from 'axios';
import { Modal } from 'bootstrap/dist/js/bootstrap.min';
import React, { useEffect, useRef, useState } from 'react';
import classes from './payement.module.css'
const PayPalLogin = ({ accounts, setAccounts }) => {
    const paypalOptions = {
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "EUR",
        intent: "capture",
    }
    const modalRef = useRef()
    const handleApprove = (data, actions) => {
        try {
            return actions.order.capture().then((details) => {
                console.log(details); // Log the capture details
                const payer = details.payer
                axios.post(`${process.env.REACT_APP_SERVER}/payments/addAccount`, payer)
                    .then(response => {
                        console.log(response)
                        fetchAccount()
                    }).catch(error => {
                        console.log(error)
                        OpenPayPalErreurModal()
                    })
            });
        } catch (error) {
            console.log(error)
        }
    };
    // Construct the PayPal login URL
    const OpenPayPalErreurModal = () => {
        const modal = new Modal(modalRef.current)
        modal.show()
    }
    const fetchAccount = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER}/payments/getAccounts`)
            .then(response => {
                console.log(response.data)
                setAccounts(response.data)
            }).catch(error => {
                console.log(error)
            })
    }
    useEffect(() => {
        fetchAccount()
    }, [])
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-12 col-md-8 col-xl-6'>
                    <h4>Liste des comptes</h4>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Nom</th>
                                <th scope="col">Prenom</th>
                                <th scope="col">Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {accounts.length > 0 && accounts.map((account, index) => (
                                <tr key={index + 1}>
                                    <td key={index}> {account.name.surname}</td>
                                    <td key={index}> {account.name.given_name}</td>
                                    <td key={index}>
                                        {account.email_address}
                                    </td>
                                </tr>
                            )
                            )}</tbody>
                    </table>
                </div>
                <div className='col-12 col-md-4 col-xl-6'>
                    <h4>Ajouter un compte paypal</h4>
                    <PayPalScriptProvider options={paypalOptions}>
                        <PayPalButtons className={classes.paypal}
                            createOrder={(data, actions) => {
                                console.log(data); // Log incoming data
                                console.log(actions)
                                // No need for purchase units for just login and ID retrieval
                                return actions.order.create({
                                    intent: 'CAPTURE',
                                    purchase_units: [
                                        {
                                            amount: {
                                                value: '0.25',
                                                currency_code: 'EUR',
                                            },
                                            description: 'Ajouter votre compte paypal',
                                        },
                                    ],
                                });
                            }}
                            onApprove={handleApprove}
                            onError={(error) => {
                                console.log(error);
                                OpenPayPalErreurModal()
                            }}
                        />
                    </PayPalScriptProvider>
                </div>
            </div>

            <div
                className="modal fade" ref={modalRef} tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
                <div
                    className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalTitleId">
                                Erreur d'ajout
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-danger" role="alert">
                                Compte déja ajouté dans ce compte
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary w-auto " data-bs-dismiss="modal">
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default PayPalLogin;
