import React, { useRef, useState } from "react";

import InscriptionImg from "../../assets/home_inscription.jpg";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import styles from './connection.module.css'; // Import the CSS module
import { Modal } from "bootstrap/dist/js/bootstrap.min";

const Connection = ({ SetIsAuthenticated }) => {
    console.log("rendering Connection")
    const modalRef = useRef(null);
    const modalRef2 = useRef(null);
    const navigate = useNavigate()
    const signIn = useSignIn()
    const { path } = useParams();
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const [formData, setFormData] = useState({ email: '', password: '' })
    const [email, setEmail] = useState('')
    const resetPassword = async () => {
        await axios.post(`${process.env.REACT_APP_SERVER}/users/resetPassword`, { email })
    }
    const onSubmit = async (e) => {
        try {
            console.log(process.env.REACT_APP_SERVER)

            e.preventDefault()
            await axios.post(`${process.env.REACT_APP_SERVER}/users/login`, formData)
                .then((res) => {

                    if (res.status === 200) {
                        let obj = {}
                        if (signIn({
                            auth: {
                                token: res.data.token,
                                type: 'Bearer'
                            },
                            userState: { userRole: res.data.userRole },
                            refresh: res.data.refreshToken
                        })) { // Only if you are using refreshToken feature
                            obj.auth = true
                            obj.role = res.data.userRole
                            console.log(res.data)
                            SetIsAuthenticated(obj)
                            console.log(res.data.userRole)
                            if (res.data.userRole === "admin" || res.data.userRole === "superadmin")
                                navigate("/Dashboard")
                            else if (path)
                                navigate("/" + path)
                            else navigate("/")
                        }
                    }
                })
                .catch((error) => {
                    if (error.response.data.error === 1) {
                        setEmailError(true);
                        setTimeout(() => setEmailError(false), 1000); // Remove the shake effect after 300ms
                    } else if (error.response.data.error === 2) {
                        openModal(modalRef)
                    } else if (error.response.data.error === 3) {
                        setPasswordError(true);
                        setTimeout(() => setPasswordError(false), 1000);
                    }
                })
        }
        catch (error) {
            console.error(error)
        }
    }
    const openModal = (modalRef) => {
        const modalElement = modalRef.current;
        if (modalElement) {
            const modalInstance = new Modal(modalElement);
            modalInstance.show();
        }
    };

    const closeModal = (modalRef) => {
        const modalElement = modalRef.current;
        if (modalElement) {
            const modalInstance = Modal.getInstance(modalElement);
            if (modalInstance) {
                modalInstance.hide();
            }
        }
    };
    const resend = async () => {
        console.log('resend')
        await axios.post(`${process.env.REACT_APP_SERVER}/users/resendCode`, formData)
    }
    return (
        <div className="bg-gradient-primary">
            <div className="container ">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-12 col-xl-10">
                        <div className="card shadow-lg o-hidden border-0 my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-flex">
                                        <div className="flex-grow-1 bg-login-image ">
                                            <img src={InscriptionImg} alt="connection" className="w-100 " />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 align-self-center">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h4 className="text-dark mb-4">Connexion</h4>
                                            </div>
                                            <form className="user needs-validation" onSubmit={onSubmit} noValidate>
                                                <div className="mb-3">
                                                    <input
                                                        className={`form-control form-control-user ${emailError ? styles.shake : ''} ${emailError ? 'is-invalid' : ''}`}
                                                        type="email" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Saisir votre adresse Email"
                                                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                        required name="email" />
                                                    <div className={`invalid-feedback `}>
                                                        Email incorrect.
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <input
                                                        className={`form-control form-control-user ${passwordError ? styles.shake : ''} ${passwordError ? 'is-invalid' : ''}`}
                                                        type="password" id="exampleInputPassword" placeholder="Saisir votre mot de passe"
                                                        required onChange={(e) => setFormData({ ...formData, password: e.target.value })} name="password" />
                                                    <div className={`invalid-feedback `}>
                                                        Mot de passe incorrect.
                                                    </div>
                                                </div>

                                                <button className="btn btn-primary d-block btn-user w-100 mb-2" type="submit">Connexion</button>
                                                <div className="text-center"><a className={`${styles.pointer} small`} onClick={() => { openModal(modalRef2) }}>Mot de passe oublié?</a></div>
                                                <hr />

                                            </form>
                                            <div className="text-center"><a className="small" href="/inscription">Créer un compte</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" ref={modalRef} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Vérifiez votre email</h5>
                            <button type="button" className="btn-close" onClick={() => { closeModal(modalRef) }} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            un email est envoyé à votre adresse email pour vérifier votre compte
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary w-auto" onClick={resend}>Renvoyer</button>
                            <button type="button" className="btn btn-primary w-auto" onClick={() => { closeModal(modalRef) }}>Email reçu</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modalId2" ref={modalRef2} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Réinitialisez votre mot de passe</h5>
                            <button type="button" className="btn-close" onClick={() => closeModal(modalRef2)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Saisir votre mail pour réinitialiser votre mot de passe
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Saisir votre adresse Email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary w-auto" onClick={() => closeModal(modalRef2)}>Annuler</button>
                            <button type="button" className="btn btn-primary w-auto" onClick={() => {
                                resetPassword();
                                closeModal(modalRef2);
                            }}>Envoyer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

};

export default Connection;