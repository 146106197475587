import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMap } from "../../../mapContext";
function Suivi() {
    const [position, setPosition] = useState({ lat: 0, lng: 0 });
    const mapContainerStyle = {
        width: "100%",
        height: "100vh",
    };
    const location = useLocation();
    const id = location.state.id
    const type = location.state.type
    console.log(location)
    const token = window.localStorage.getItem("_auth");
    const [zoom, setZoom] = useState(10);
    useEffect(() => {
        console.log('fetching position', id)
        const eventSource = new EventSource(`${process.env.REACT_APP_SERVER}/tracking?id=${id}&type=${type}&token=${token}`);
        eventSource.onmessage = (event) => {
            console.log('Event received:', event);
            const data = JSON.parse(event.data);
            console.log('position:', data);

            if (data && data.lat && data.lng) {
                setPosition({ lat: data.lat, lng: data.lng });
            }
        };

        eventSource.onerror = (error) => {
            console.error('EventSource failed:', error);
        };

        // Close the EventSource connection when the component unmounts
        return () => {
            eventSource.close();
        };
    }, [id]);
    const options = {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true
    };
    const { isLoaded } = useMap()

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            center={position}
            options={options}
        >
            <MarkerF position={position} />
        </GoogleMap>
    ) : (<></>);
}

export default Suivi;