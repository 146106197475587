import { useEffect, useState } from "react";
import { Row, Card, CardHeader, CardBody, CardTitle, Table } from "reactstrap";
import axios from "axios";
import PaginationComponent from "../../../components/paginations/pagination";
function Paiements() {
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState()
    const [paiements, setPaiements] = useState([])

    const token = window.localStorage.getItem("_auth");
    const fetchCount = async () => {
        try {
            console.log("fetching data")
            let url = `${process.env.REACT_APP_SERVER}/userPaiments/allCount`;
            const response = await axios.get(url);
            const data = response.data;
            const calculatedLastPage = Math.ceil(data.count / 10);
            setLastPage(calculatedLastPage);

            console.log(calculatedLastPage);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const fetchData = async () => {
        const eventSource = new EventSource(`${process.env.REACT_APP_SERVER}/userPaiments/all?token=${token}&page=${page}`);

        eventSource.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                console.log("Received event data:", data);


                setPaiements((prevPaiements) => {
                    const existingPaymentIndex = prevPaiements.findIndex((p) => p._id === data._id);
                    if (existingPaymentIndex !== -1) {
                        // Update the existing payment
                        const updatedPaiements = [...prevPaiements];
                        updatedPaiements[existingPaymentIndex] = data;
                        return updatedPaiements;
                    } else {
                        // Add the new payment
                        return [...prevPaiements, data];
                    }
                });
            } catch (error) {
                console.error("Error processing event data:", error);
            }
        };

        // Handle EventSource errors
        eventSource.onerror = (error) => {
            console.error("EventSource error:", error);
            eventSource.close();
        };

        // Cleanup EventSource on component unmount
        return () => {
            eventSource.close();
        };
    };

    useEffect(() => {
        fetchCount();
        fetchData(page);
    }, []); // Only run once on mount

    return (
        <Row style={{ maxWidth: "100%" }} >
            <Card className="Card" style={{ maxWidth: "100%" }}  >
                <CardHeader className="CardHeader">
                    <CardTitle tag="h4">Liste des paiements</CardTitle>
                </CardHeader>
                <CardBody className="pt-0">
                    <div className="accordion" id="accordionExample">
                        <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                                <tr>
                                    <th>Date</th>
                                    <th>Montant</th>
                                    <th>Statut</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paiements.length > 0 && paiements.map((paiement, index) => (
                                    <tr key={index}>
                                        <td>{new Date(paiement.date_creation).toLocaleDateString()}</td>
                                        <td>{paiement.montant}</td>
                                        <td>{paiement.etat}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
                <PaginationComponent page={page} lastPage={lastPage} setPage={setPage} fetchData={fetchData} />

            </Card>

        </Row>

    );
}
export default Paiements;