import { useEffect, useState } from "react";
import styles from './chat.module.css';
import axios from "axios";
import ChatBox from "./ChatBox";
function ChatRow({ chatsBalls, setChatsBalls, openedChats, setOpenedChats }) {
    const [selectedChat, setSelectedChat] = useState(undefined)

    const [selectedOffre, setSelectedOffre] = useState(undefined)
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            const currentWidth = window.innerWidth;
            setWidth(currentWidth);

            if (currentWidth < 780) {
                setOpenedChats(prevChats => (prevChats.length > 1 ? prevChats.slice(-1) : prevChats));
            }
        };

        // Set initial width and openedChats based on current window width
        handleResize();

        window.addEventListener('resize', handleResize);
    }, [openedChats]);

    const addChat = async (chatId) => {
        if (openedChats.includes(chatId)) return;
        console.log(chatId);
        setOpenedChats([...openedChats, chatId]);
    }
    const getChatBallLetter = (chat) => {
        return chat.receiver.nom[0].toUpperCase() + chat.receiver.prenom[0].toUpperCase();
    }
    return (
        <div className={`${styles.fixedContainer} position-fixed d-flex justify-content-end`}>
            <div className={`${styles.chatBoxContainer} d-flex align-items-end justify-content-end`}>
                {openedChats.map((chat, index) => (
                    <ChatBox key={index} chat={chat} setChats={setOpenedChats} setSelectedChat={setSelectedChat} />
                ))}
            </div>
            <div className={`${styles.chatBallContainer} d-flex align-items-end`}>
                {chatsBalls.map((chatBall, index) => (
                    <div
                        role="button"
                        onClick={() => addChat(chatBall)}
                        key={index}
                        className={`${styles.chatBall} rounded-circle`}
                    >
                        <p className={styles.chatBallText}>{getChatBallLetter(chatBall)}</p>
                    </div>
                ))}
            </div>

            <div className="modal modal-md fade " id="modalId" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" >
                    <div className="modal-content" style={{ backgroundColor: "#eaf0ff" }}>
                        <div className="modal-header" style={{ backgroundColor: "#2774f6" }}>
                            <h1 className="modal-title fs-5 text-light" id="exampleModalLabel">Consultre l'offre</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" >
                            {(selectedChat && selectedChat.offreType === "TtrajetOffre") && <div className="row">
                                {(selectedChat.offre.acceptedOffre == "counterOffre") && <div className="col ">

                                    <div className="card rounded-5 p-3 h-100" >
                                        <h5 className="card-title">Offre de transporteur</h5>
                                        <div className="card-body">
                                            <h6 className="fw-bold">DÉPART :</h6>
                                            <p>
                                                Récupérer main à main : {selectedChat && selectedChat.offre.counterOffre.mam1 === true ? "Oui" : "Non"}
                                            </p>
                                            <p>
                                                Récupérer en point relais : {(selectedChat && selectedChat.offre.counterOffre.relai1) ? (selectedChat.offre.counterOffre.relai1.address) : ("Non")}
                                            </p>
                                            <h6 className="fw-bold">ARRIVÉ :</h6>
                                            <p>
                                                Déposer main à main : {selectedChat && selectedChat.offre.counterOffre.mam2 === true ? "Oui" : "Non"}
                                            </p>
                                            <p>
                                                Déposer en point relais : {(selectedChat && selectedChat.offre.counterOffre.relai2) ? (selectedChat.offre.counterOffre.relai2.address) : ("Non")}
                                            </p>
                                            <div> <b>PRIX : </b> {(selectedChat && selectedChat.offre.counterOffre.prix) ? (selectedChat.offre.counterOffre.prix) : ("Introuvable")}</div>

                                        </div>
                                        <div className="card-body"></div>
                                    </div>
                                </div>}
                                {(selectedChat.offre.acceptedOffre == "offre") &&
                                    <div className="col">
                                        <div className="card rounded-5 p-3 h-100" >
                                            <h5 className="card-title">Offre de particulier</h5>
                                            <div className="card-body">
                                                <fieldset>
                                                    <h6 className="fw-bold">DÉPART</h6>
                                                    <div className="d-flex">
                                                        <p className="me-4">Récupérer main à main :  {selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.mam1 ? 'Oui' : "Non"}</p>


                                                    </div>
                                                    <div className="d-flex">
                                                        <p className="me-4">Récupérer en point relais : {selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.relai1 ? selectedChat.offre.offre.relai1.address : "Non"} </p>
                                                    </div>

                                                </fieldset>

                                                <fieldset>
                                                    <h6 className="fw-bold">ARRIVÉ</h6>

                                                    <div className="d-flex">
                                                        <p className="me-4">Déposer main à main : {selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.mam2 ? 'Oui' : "Non"}</p>

                                                    </div>

                                                    <div className="d-flex">
                                                        <p className="me-4">Déposer en point relais :{selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.relai2 ? selectedChat.offre.offre.relai2.address : "Non"}</p>

                                                    </div>

                                                </fieldset>
                                                <fieldset>
                                                    <div> <b>PRIX : </b> {(selectedChat && selectedChat.offre.offre.prix) ? (selectedChat.offre.offre.prix) : ("Introuvable")}</div>

                                                </fieldset>
                                            </div>


                                        </div>

                                    </div>
                                }
                            </div>}
                            {(selectedChat && selectedChat.offreType === "TrajetOffre") &&
                                <div className="row">
                                    <div className="col ">
                                        <div className="card rounded-5 p-3 h-100" >
                                            <h5 className="card-title">Offre de trajet</h5>
                                            <div className="card-body">
                                                <h6 className="fw-bold">DÉPART :</h6>
                                                <p>
                                                    Adresse de départ : {selectedChat.trajet.from_address.name}
                                                </p>
                                                <p>
                                                    Date de départ : {new Date(selectedChat.trajet.go_time).toLocaleDateString('fr-FR')}
                                                </p>
                                                <h6 className="fw-bold">ARRIVÉ :</h6>
                                                <p>
                                                    Adresse d'arrivé : {selectedChat.trajet.to_address.name}
                                                </p>
                                                <p>
                                                    Date d'arrivé : {new Date(selectedChat.trajet.arrive_time).toLocaleDateString('fr-FR')}
                                                </p>
                                                <div> <b>PRIX : </b> {selectedChat.offre.prix}</div>

                                            </div>
                                            <div className="card-body"></div>
                                        </div>
                                    </div>


                                </div>}
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary w-auto" data-bs-dismiss="modal"
                                onClick={() => {
                                    setSelectedChat(undefined)
                                }}>Annuler</button>

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal modal-xl fade " id="modalId5" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" >
                    <div className="modal-content" style={{ backgroundColor: "#eaf0ff" }}>
                        <div className="modal-header" style={{ backgroundColor: "#2774f6" }}>
                            <h1 className="modal-title fs-5 text-light" id="exampleModalLabel">Consultre l'offre</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" >
                            {(selectedChat && selectedChat.offreType === "TtrajetOffre") && <div className="row">
                                <div className="col ">
                                    <div className="card rounded-5 p-3 h-100" >
                                        <h5 className="card-title">Offre de transporteur</h5>
                                        <div className="card-body">
                                            <h6 className="fw-bold">DÉPART :</h6>
                                            <p>
                                                Récupérer main à main : {selectedChat && selectedChat.offre.counterOffre.mam1 === true ? "Oui" : "Non"}
                                            </p>
                                            <p>
                                                Récupérer en point relais : {(selectedChat && selectedChat.offre.counterOffre.relai1) ? (selectedChat.offre.counterOffre.relai1.address) : ("Non")}
                                            </p>
                                            <h6 className="fw-bold">ARRIVÉ :</h6>
                                            <p>
                                                Déposer main à main : {selectedChat && selectedChat.offre.counterOffre.mam2 === true ? "Oui" : "Non"}
                                            </p>
                                            <p>
                                                Déposer en point relais : {(selectedChat && selectedChat.offre.counterOffre.relai2) ? (selectedChat.offre.counterOffre.relai2.address) : ("Non")}
                                            </p>
                                            <div> <b>PRIX : </b> {(selectedChat && selectedChat.offre.counterOffre.prix) ? (selectedChat.offre.counterOffre.prix) : ("Introuvable")}</div>

                                        </div>
                                        <div className="card-body"></div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card rounded-5 p-3 h-100" >
                                        <h5 className="card-title">Offre de particulier</h5>
                                        <div className="card-body">
                                            <fieldset>
                                                <h6 className="fw-bold">DÉPART</h6>
                                                <div className="d-flex">
                                                    <p className="me-4">Récupérer main à main :  {selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.mam1 ? 'Oui' : "Non"}</p>


                                                </div>
                                                <div className="d-flex">
                                                    <p className="me-4">Récupérer en point relais : {selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.relai1 ? selectedChat.offre.offre.relai1.address : "Non"} </p>
                                                </div>

                                            </fieldset>

                                            <fieldset>
                                                <h6 className="fw-bold">ARRIVÉ</h6>

                                                <div className="d-flex">
                                                    <p className="me-4">Déposer main à main : {selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.mam2 ? 'Oui' : "Non"}</p>

                                                </div>

                                                <div className="d-flex">
                                                    <p className="me-4">Déposer en point relais :{selectedChat && selectedChat.offre.offre && selectedChat.offre.offre.relai2 ? selectedChat.offre.offre.relai2.address : "Non"}</p>

                                                </div>

                                            </fieldset>
                                            <fieldset>
                                                <div> <b>PRIX : </b> {(selectedChat && selectedChat.offre.offre.prix) ? (selectedChat.offre.offre.prix) : ("Introuvable")}</div>

                                            </fieldset>
                                        </div>


                                    </div>

                                </div>

                            </div>}

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary w-auto" data-bs-dismiss="modal"
                                onClick={() => {
                                    setSelectedChat(undefined)
                                }}>Annuler</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatRow;